/* eslint-disable react/jsx-filename-extension */
import './styles/index.css';

import React from 'react';

import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { Notification } from './components/Notification';
import store, { persistor } from './redux/store';
import reportWebVitals from './reportWebVitals';
import Router from './navigation/router';
import { AuthProvider } from './pages/Auth/JwtContext';

// @mui
import { createTheme, ThemeProvider } from '@mui/material/styles';

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';

// google
import { GoogleOAuthProvider } from '@react-oauth/google';

const theme = createTheme({
  palette: {
    primary: {
      main: "#8b5e3c",
    },
    secondary: {
      main: "#f1ebe4",
    },
  },
  typography: {
    fontFamily: 'Comfortaa-Regular',
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <GoogleOAuthProvider clientId="454742539255-g96ldcbh7hfuk5pb47545tv7rcukgv89.apps.googleusercontent.com">
        <ThemeProvider theme={theme}>
          <Provider store={store}>
            <PersistGate persistor={persistor}>
              <Router />
              <Notification />
            </PersistGate>
          </Provider>
        </ThemeProvider>
      </GoogleOAuthProvider>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
