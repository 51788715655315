import "react-loading-skeleton/dist/skeleton.css";

import React, { useCallback, useEffect, useMemo, useState, useRef } from "react";

import _ from "lodash";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
  useLocation,
  useNavigate,
  useSearchParams,
  useParams,
} from "react-router-dom";
import { selectCategory, getCatWithProducts } from '../../redux/slices/product.slice';
import { setTableId } from '../../redux/slices/order.slice';

import illustrationsPromo from "../../assets/illustrations/mobile-search-undraw.png";
import images from "../../assets/images/person-with-a-coffee.webp";
import Header from "../../components/Header";
import useDocumentTitle from "../../utils/documentTitle";
import ProgressiveImage from 'react-progressive-image';

// @mui
import {
  Badge,
  Box,
  Card,
  CardContent,
  Fab,
  Grid,
  Stack,
  Tab,
  Tabs,
  Typography,
  Skeleton,
  Divider,
  Chip,
  Button,
  SwipeableDrawer,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Iconify from '../../components/iconify';
import Carousel from "../../components/carousel";
import Image from "../../components/image";
import ProductDetail from "./ProductDetail";

export default function Products(props) {
  const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [ddMenu, setDdmenu] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [isShake, setIsShake] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const shakeRef = useRef(null);
  const [search, setSearch] = useState(
    searchParams.has("q") ? searchParams.get("q") : undefined
  );
  const { selectedCategory, catWithProducts, error, isLoading, cart } = useSelector((state) => state.product);
  const { tableId } = useSelector((state) => state.order);
  const carouselRef = useRef();
  const subCategories = catWithProducts[selectedCategory]?.SubCategory ? JSON.parse(catWithProducts[selectedCategory].SubCategory) : [];
  const carouselSettings = {
    dots: false,
    arrows: false,
    slidesToShow: 1,
    draggable: false,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
    beforeChange: (current, next) => setCurrentIndex(next),
  };

  useDocumentTitle(props.title);

  useEffect(() => {
    carouselRef.current?.slickGoTo(currentIndex);
  }, [currentIndex]);

  const toggleDdmenu = () => {
    setDdmenu(!ddMenu);
  };

  const navigate = useNavigate();

  const navigateWithParams = (newParams) => {
    const searchParams = new URLSearchParams(location.search);
    Object.entries(newParams).forEach(([key, value]) =>
      searchParams.set(key, value)
    );
    navigate(`${location.pathname}?${searchParams}`);
  };

  const navigateDeleteParams = (deleteParams) => {
    const searchParams = new URLSearchParams(location.search);
    Object.entries(deleteParams).forEach(([key, value]) =>
      searchParams.delete(key)
    );
    navigate(`${location.pathname}?${searchParams}`);
  };

  const delayedSearch = useCallback(
    _.debounce((q) => {
      navigateWithParams({ q });
    }, 1500),
    []
  );

  useEffect(() => {
    if (search) {
      delayedSearch(search);
    } else {
      navigateDeleteParams({ q: null });
    }
  }, [search]);

  useEffect(() => {
    dispatch(getCatWithProducts());
  }, []);

  useEffect(() => {
    if (params.tableId) {
      dispatch(setTableId(params.tableId));
    }
  }, [params.tableId]);

  useEffect(() => {
    if (isShake && shakeRef.current) {
      const handleAnimationEnd = () => {
        setIsShake(false);
      };

      shakeRef.current.addEventListener('animationend', handleAnimationEnd);

      return () => {
        shakeRef.current.removeEventListener('animationend', handleAnimationEnd);
      };
    }
  }, [isShake]);

  const handleChange = (event, newValue) => {
    dispatch(selectCategory(newValue));
  };

  const handleNavigate = (name) => {
    if (!params.tableId) {
      // navigate('/qrScan');
      window.scrollTo(0, 0);
      setIsShake(true);
    } else {
      navigate(`/products/${params.tableId}/detail/${name}`)
    }
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setOpenDrawer(open);
  };

  return (
    <>
      <Header />
      <Box
        sx={{
          position: 'sticky',
          top: {
            xs: 70,
            md: 94,
          },
          zIndex: 1,
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <Tabs
          value={selectedCategory}
          onChange={handleChange}
          variant="scrollable"
          aria-label="scrollable force tabs example"
        >
          {catWithProducts.map((tab, idx) => (
            <Tab label={tab.ProductCategory} key={idx} value={idx} />
          ))}
        </Tabs>
      </Box>

      <main className="flex flex-col global-px">
        {/* <Box mt={2}>
          <Carousel
            {...carouselSettings}
            ref={carouselRef}
          >
            {products.map((item, idx) => (
              <Image
                key={idx}
                src={item.image}
                alt={item.name}
                sx={{
                  width: 0.9,
                  borderRadius: 2,
                  margin: 'auto',
                }}
              // disabledEffect
              />
            ))}
          </Carousel>
        </Box> */}

        <section className="flex-1 flex flex-col items-center gap-5 py-5">
          {!params.tableId && (
            <Button
              ref={shakeRef}
              endIcon={<Iconify icon="uil:qrcode-scan" width={24} />}
              sx={{
                marginLeft: 'auto',
                textTransform: 'capitalize',
                backgroundColor: isShake ? '#f8f2ed' : 'transparent',
                zoom: isShake ? 1.3 : 1,
                transition: 'zoom 0.5s ease-in-out',
                animation: isShake ? 'shake 0.82s cubic-bezier(.36,.07,.19,.97) both' : 'none',
                transform: 'translate3d(0, 0, 0)',
                backfaceVisibility: 'hidden',
                perspective: '1000px'
              }}
              color="primary"
              aria-label="QR Scan"
              onClick={() => navigate('/qrScan')}
            >
              <spam className="font-bold mr-1">Scan Table</spam>
            </Button>
          )}
          <h2 className="font-bold text-2xl">Highlight Today</h2>
          <p className="text-center font-normal">
            Popular items today.
            <br />
            Get it now before it&apos;s gone!
          </p>
          <div className="flex justify-center w-full">
            {isLoading ? (
              <Box
                px={2}
                width={1}
              >
                <Grid container spacing={2}>
                  {[...Array(4)].map((_, idx) => (
                    <Grid key={idx} item xs={6} md={3}>
                      <Skeleton
                        sx={{
                          width: { xs: 150, md: 220 },
                          height: { xs: 300, md: 300 },
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            ) : (
              <Grid container>
                {subCategories.length > 0 ? (
                  subCategories.map((item, idx) => (
                    <Grid key={idx} item xs={12} mb={3}>
                      <Typography variant="h6" component="h2">
                        {item.ProductCategory}
                      </Typography>
                      <Divider />
                      <Grid container spacing={2} justifyContent={JSON.parse(item.ProductListing).length > 0 ? 'flex-start' : 'center'}>
                        {JSON.parse(item.ProductListing).length > 0 ? (
                          JSON.parse(item.ProductListing).map((item, idx) => (
                            <Grid key={idx} mt={1} item xs={6} md={3}>
                              <Card
                                // onClick={() => setOpenDrawer(true)}
                                onClick={() => handleNavigate(item.ProductName)}
                                sx={{
                                  position: 'relative', // Make the card position relative for absolute positioning of the button
                                  cursor: 'pointer',
                                  borderRadius: '8px', // Increased border radius for smoother edges
                                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // Soft shadow for depth
                                  transition: 'box-shadow 0.3s ease-in-out', // Smooth transition for hover effect
                                  '&:hover': {
                                    boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)', // Elevate the card on hover
                                  },
                                  backgroundColor: '#f8f2ed',
                                  // overflow: 'hidden', // Hide overflowing content
                                  width: '100%', // Ensure card takes full width
                                  height: '100%', // Ensure card takes full height
                                }}
                              >
                                <CardContent>
                                  <Grid container justifyContent='center' alignItems='center' spacing={2}>
                                    <Grid item xs={12} sm={5}>
                                      <ProgressiveImage src={item.ProductImage || images} placeholder="hello">
                                        {(src, loading) => {
                                          return loading ? (
                                            <Skeleton
                                              sx={{
                                                width: "100%",
                                                height: "100%",
                                                minHeight: "150px",
                                              }}
                                            />
                                          ) : (
                                            <img
                                              style={{
                                                width: '100%',
                                                height: '100%',
                                                minHeight: '150px',
                                                maxHeight: '150px',
                                                borderRadius: '8px',
                                                display: 'block',
                                                objectFit: 'cover',
                                                // Add a transition for the opacity
                                                transition: 'opacity 0.5s',
                                                // Change the opacity based on whether the image is still loading
                                                opacity: loading ? 0.5 : 1,
                                              }}
                                              src={src}
                                              alt={item.ProductName}
                                            />
                                          )
                                        }}
                                      </ProgressiveImage>
                                    </Grid>
                                    <Grid item xs={12} sm={7}>
                                      <Typography variant="h6" component="h2" gutterBottom>
                                        {item.ProductName}
                                      </Typography>
                                      <Box sx={{ mb: 4 }}>
                                        {item.ProductTag && item.ProductTag.split(',').map((tag, index) => (
                                          <Chip key={index} label={tag} variant="outlined" color="primary" sx={{ mr: 0.1, mb: 1 }} />
                                        ))}
                                      </Box>
                                      {/* {item.discountPrice && (
                                        <Stack direction="row" spacing={1} alignItems="center">
                                          <Typography color="error" variant="h6" fontWeight='bold'>
                                            {item.discountPrice}
                                          </Typography>
                                          <Typography color="error" variant="body2">
                                            {item.discountType}
                                          </Typography>
                                        </Stack>
                                      )} */}
                                      <div
                                        dangerouslySetInnerHTML={{ __html: item.MinProductBasedPrice }}
                                        className="my-5"
                                      />
                                    </Grid>
                                  </Grid>
                                </CardContent>
                              </Card>
                            </Grid>
                          ))
                        ) : (
                          <div className="flex flex-col text-center">
                            <p className="text-black font-medium text-sm my-5">
                              No item
                            </p>
                          </div>
                        )}
                      </Grid>
                    </Grid>
                  )
                  )) : (
                  <Grid item xs={12}>
                    <div className="flex flex-col justify-center items-center">
                      <img src={illustrationsPromo} width={200} />
                      <p className="text-primary text-xl font-semibold">Too bad :(</p>
                      <p className="text-black font-medium text-sm my-5">
                        No item under this category yet
                      </p>
                    </div>
                  </Grid>
                )}
              </Grid>
            )}
          </div>
        </section>
      </main>

      {/* <SwipeableDrawer
        anchor={"bottom"}
        open={openDrawer}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        {
          <Box
            sx={{ width: 'auto' }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          >
            <ProductDetail />
          </Box>
        }
      </SwipeableDrawer> */}
      <Box
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
        }}
      >
        <Badge
          badgeContent={cart.length}
          color="error"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{
            '& .MuiBadge-badge': {
              right: 4,
              top: 10,
              border: `2px solid ${theme.palette.background.paper}`,
              padding: '0 4px',
              zIndex: 9999,
              fontSize: '0.6rem',
            },
          }}
        >
          <Fab
            color="primary"
            aria-label="cart"
            onClick={() => navigate('/cart')}
          >
            <Iconify icon="carbon:shopping-cart" />
          </Fab>
        </Badge>
      </Box>
    </>
  );
};
