import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import Footer from '../components/Footer';
import Header from '../components/Header';

// assets
import beanIcon from '../assets/icons/bean.svg';
import checkCircle from '../assets/icons/check-circle.svg';
import placeIcon from '../assets/icons/place.svg';
import starIcon from '../assets/icons/star.svg';
import staffIcon from '../assets/icons/user.svg';
import loadingImage from '../assets/images/loading.svg';
import amazonLogo from '../assets/images/partners/amazon.svg';
import discordLogo from '../assets/images/partners/discord.svg';
import netflixLogo from '../assets/images/partners/netflix.svg';
import redditLogo from '../assets/images/partners/reddit.svg';
import spotifyLogo from '../assets/images/partners/spotify.svg';
import phProfile from '../assets/images/placeholder-profile.jpg';
import productImage1 from '../assets/images/product-1.webp';
import odaCafeImage from '../assets/images/oda-cafe.png';

// @mui
import { Box, Grid, Link } from '@mui/material';

// utils
import { n_f } from '../utils/helpers';

// google maps
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { getTopProducts } from '../redux/slices/product.slice';

const Mainpage = () => {
  const dispatch = useDispatch();

  const { topProducts } = useSelector((state) => state.product);

  const provide = [
    "High quality beans",
    "Various choice of coffee",
    "Chat with our staff to get better experience for ordering",
    "Join the membership to get a special promo for lifetime",
  ];

  const reviews = [
    {
      name: "Foo Barr",
      text: "Wow... I am very happy to spend my whole day here. the Wi-fi is good, and the coffee and meals tho. I like it here!! Very recommended!",
    },
    {
      name: "Yessica Christy",
      text: "I like it because I like to travel far and still can make my day better just by drinking their Hazelnut Latte",
    },
    {
      name: "Kim Young Jou",
      text: "This is very unusual for my taste, I haven’t liked coffee before but their coffee is the best! and yup, you have to order the chicken wings, the best in town",
    },
  ];

  const mainData = [
    {
      id: 1,
      title: 'Beans',
      data: 9,
      icon: staffIcon,
    },
    {
      id: 2,
      title: 'Drinks',
      data: 12,
      icon: beanIcon,
    },
    {
      id: 3,
      title: 'Flavours',
      data: 30,
      icon: placeIcon,
    },
  ];

  const partnerLogos = [
    {
      id: 1,
      logo: netflixLogo,
    },
    {
      id: 2,
      logo: redditLogo,
    },
    {
      id: 3,
      logo: amazonLogo,
    },
    {
      id: 4,
      logo: discordLogo,
    },
    {
      id: 5,
      logo: spotifyLogo,
    },
  ];

  const favouriteProducts = [
    {
      id: 1,
      title: 'Hazelnut Latte',
      description: 'Hazelnut syrup with wanilla whipped cream and sliced banana on top',
      price: 25,
      image: productImage1,
    },
    {
      id: 2,
      title: 'Iced Coffee',
      description: 'Beef bacon, beef meat, chicken meat, mozarella, yellow cheese, eggs',
      price: 20,
      image: productImage1,
    },
    {
      id: 3,
      title: 'Pinky Promise',
      description: 'Homemade pinky promise with chocolate and milk',
      price: 15,
      image: productImage1,
    },
  ];

  const center = {
    lat: 1.5148852984714425,
    lng: 110.35493065434088,
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyByNvL1zPDKSBRXlU_0TdGNBgGCnNYSDiY"
  });

  const [map, setMap] = React.useState(null)

  useEffect(() => {
    dispatch(getTopProducts(3));
  }, [dispatch]);

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);

    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  const navigate = useNavigate();

  return (
    <>
      <Header />
      <Box component="main">
        <section className="bg-main bg-cover bg-center py-20 text-white font-bold">
          {/* <ThreeDScene zIndex={999} /> */}
          <div className="global-px">
            <div className="flex flex-col gap-6 w-[75%] lg:w-[50%] text-sm">
              <h2 className="text-4xl font-bold">
                Welcome to ODA
              </h2>
              <p className='font-normal'>
                The future of coffee where technology and coffee unite to elevate your daily brew into an extraordinary experience.
              </p>
              <div className="mt-5 font-bold">
                <Link
                  className="bg-secondary px-6 py-4 text-primary rounded-xl"
                  href={'/products'}
                  sx={{
                    textDecoration: 'none',
                  }}
                >
                  Our Menu
                </Link>
              </div>
            </div>
            <section className="relative bg-white mt-20 mb-[-9rem] rounded-xl shadow-xl text-quartenary flex flex-row py-5 justify-center items-center text-center md:text-left">
              {mainData.map((item, idx) => (
                <aside key={idx} className="flex-1 border-r-2 py-2 md:py-6 flex flex-col md:flex-row justify-center gap-3 md:gap-8 items-center">
                  <div>
                    <div className="bg-secondary rounded-full p-2 w-10 aspect-square flex justify-center items-center">
                      <img src={item.icon} alt="" />
                    </div>
                  </div>
                  <div>
                    <p className="text-md lg:text-xl font-bold">{item.data}</p>
                    <p className="font-normal text-primary">{item.title}</p>
                  </div>
                </aside>
              ))}
            </section>
          </div>
        </section>
        <div className="mb-8 md:mb-20"></div>
        <section className="flex flex-col lg:flex-row global-px py-20 lg:gap-32">
          <div className="flex-1 img">
            <img src={odaCafeImage} alt="" width="100%" />
          </div>
          <div className="flex-1 flex flex-col justify-center gap-5">
            <h2 className="text-quartenary font-semibold text-[35px]">
              We Provide Good Coffee and Good Vibes
            </h2>
            <p className="text-gray-700 font-normal">
              You can explore the menu that we provide with fun and have their
              own taste and make your day better.
            </p>
            <ul className="flex flex-col max-w-md space-y-1 text-gray-700 list-inside gap-4">
              {provide.map((text, idx) => (
                <li className="flex items-center gap-4" key={idx}>
                  <img src={checkCircle} alt="" />
                  <span className='font-light'>{text}</span>
                </li>
              ))}
            </ul>
          </div>
        </section>
        <section className="global-px py-8 md:py-20">
          <div className="flex flex-col items-center">
            <h2 className="text-4xl text-quartenary font-semibold mb-5 text-center">
              Here is People’s Favorite
            </h2>
            <p className="text-base text-gray-700 text-center font-normal">
              Let’s choose and have a bit taste of poeple’s favorite. It might
              be yours too!
            </p>
          </div>
          <Grid container mt={10} spacing={2}>
            {topProducts.map((item, idx) => (
              <Grid
                item
                xs={12}
                md={4}
                flexDirection="column"
                key={idx}
              >
                <Box
                  className="flex-1 flex flex-col justify-center border-gray-400 border rounded-xl px-5 py-5 md:py-12 items-center gap-5 text-base"
                >
                  <img
                    src={item.ProductImage}
                    alt=""
                    width="140px"
                    className="rounded-full mb-7"
                  />
                  <h3 className="text-lg font-medium">{item.ProductName}</h3>
                  {/* <p className="font-medium text-center">{item.description}</p> */}
                  <p className="font-medium text-2xl"><span className='font-medium text-lg'>from</span> RM {n_f(item.BaseProductPrice)}</p>
                  <button
                    className="bg-primary text-tertiary px-9 py-3 rounded-3xl font-bold"
                    onClick={() => navigate(`/products`)}
                  >
                    Order Now
                  </button>
                </Box>

              </Grid>
            ))}
          </Grid>
        </section>
        <section className="global-px py-8 md:py-20">
          <div className="flex flex-col items-center mb-8 md:mb-20">
            <h2 className="text-4xl text-quartenary font-semibold mb-5 text-center">
              Visit Our Store
            </h2>
            <p className="text-base text-gray-700 text-center font-normal">
              Find our store in your city. Enjoy the best coffee package that
              you never have before.
            </p>
          </div>
          <div className="mt-10">
            {isLoaded ? (
              <GoogleMap
                mapContainerStyle={{
                  width: "100%",
                  height: "400px",
                }}
                center={center}
                zoom={10}
                onLoad={onLoad}
                onUnmount={onUnmount}
              >
                <Marker position={center} />
              </GoogleMap>
            ) : (
              <section className="flex justify-center items-center py-7">
                <img src={loadingImage} className="invert text-primary" />
              </section>
            )}
          </div>
        </section>
        {/* <section className="global-px py-8 md:py-20">
          <div className="flex flex-col items-center mb-8 md:mb-20 text-center">
            <h2 className="text-3xl md:text-[35px] text-quartenary font-semibold mb-5">
              Loved by Customer of
              <br /> Happy Customer
            </h2>
            <p className="text-[1rem] text-center max-w-[555px] text-primary font-normal">
              These are the stories of our customers who have visited us with
              great pleasure.
            </p>
          </div>
          <div className="overflow-auto flex flex-row gap-5 flex-wrap lg:flex-nowrap ">
            {reviews.map((review, idx) => {
              return (
                <div
                  className="w-[400px] border-gray-300 hover:border-tertiary border-2 duration-200 rounded-xl p-7 space-y-4 hover:shadow-2xl mx-auto"
                  key={idx}
                >
                  <div className="flex flex-row gap-2 items-center">
                    <img
                      src={phProfile}
                      alt=""
                      className="w-14 aspect-square object-cover rounded-full"
                    />
                    <div className="flex-1">
                      <p className="font-semibold text-quartenary text-lg">
                        {review.name}
                      </p>
                      <p className="text-primary text-sm font-normal">Warsaw, Poland</p>
                    </div>
                    <div className="flex flex-row items-center gap-2 font-normal">
                      4.5 <img src={starIcon} alt="" />
                    </div>
                  </div>
                  <p className="text-quartenary font-light">“{review.text}</p>
                </div>
              );
            })}
          </div>
        </section> */}
        <section className="global-px z-10 relative w-full mb-6 md:mb-[-6rem]">
          <div className="shadow-xl rounded-xl flex flex-col md:flex-row py-10 md:py-14 px-8 md:px-16 bg-white text-center md:text-left">
            <aside className="flex-1 space-y-4 mb-5 md:mb-0">
              <p className="text-3xl font-semibold">Check our menu today!</p>
              <p className="text-primary font-normal">
                Let&apos;s see the menu and choose your best coffee.
              </p>
            </aside>
            <aside className="hidden lg:block lg:flex-1"></aside>
            <aside className="flex-1 flex flex-col justify-center">
              <button
                className="ml-auto w-[100%] md:w-[75%] bg-primary rounded-xl py-4 text-tertiary font-bold"
                onClick={() => navigate('/products')}
              >
                Get your coffee now
              </button>
            </aside>
          </div>
        </section>
      </Box>
      <Footer />
    </>
  );
};

export default Mainpage;

// const ThreeDScene = ({ zIndex }) => {
//   return (
//     <Canvas shadows camera={{ position: [0, 0, 10], fov: 25 }} sx={{ zIndex: zIndex }} >
//       {/* <AmbientLight intensity={0.5} /> */}
//       {/* <SpotLight position={[10, 10, 10]} angle={0.15} penumbra={1} shadow-mapSize={2048} castShadow /> */}
//       <PresentationControls
//         global
//         config={{ mass: 2, tension: 500 }}
//         snap={{ mass: 4, tension: 1500 }}
//         rotation={[0, 0.3, 0]}
//         polar={[-Math.PI / 18, Math.PI / 18]}
//         azimuth={[-Math.PI / 1.4, Math.PI / 2]}>
//         <Watch rotation={[-Math.PI / 2, 0, 0]} position={[0, 0.25, 0]} scale={0.003} />
//       </PresentationControls>
//       <ContactShadows position={[0, -1.4, 0]} opacity={0.75} scale={10} blur={3} far={4} />
//       <Environment preset="city" />
//     </Canvas>
//   );
// };

// const Watch = (props) => {
//   const { nodes, materials } = useGLTF('/watch-v1.glb')
//   return (
//     <group {...props} >
//       {/* <Mesh castShadow receiveShadow geometry={nodes.Object006_watch_0.geometry} material={materials.watch} /> */}
//       <mesh castShadow receiveShadow geometry={nodes.Object006_watch_0.geometry} material={materials.watch} />

//     </group>
//   )
// }
