import "react-loading-skeleton/dist/skeleton.css";

import React, { useCallback, useEffect, useRef, useState } from "react";

import _, { isEmpty } from "lodash";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { setTableId } from '../../redux/slices/order.slice';
import { clearProduct, getCatWithProducts, getProductBySlug } from '../../redux/slices/product.slice';

import logo from "../../assets/images/oda-cafe.png";
import useDocumentTitle from "../../utils/documentTitle";

import Image from "../../components/image/Image";

// @mui
import {
  Box,
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  SwipeableDrawer,
  Typography,
  CardMedia,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Iconify from '../../components/iconify';

export default function Catalog({ title }) {
  const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const shakeRef = useRef(null);
  const [search, setSearch] = useState(
    searchParams.has("q") ? searchParams.get("q") : undefined
  );
  const { error, isLoading, product, catWithProducts } = useSelector((state) => state.product);
  const carouselRef = useRef();
  useDocumentTitle(title);

  const navigate = useNavigate();

  const navigateWithParams = (newParams) => {
    const searchParams = new URLSearchParams(location.search);
    Object.entries(newParams).forEach(([key, value]) =>
      searchParams.set(key, value)
    );
    navigate(`${location.pathname}?${searchParams}`);
  };

  const navigateDeleteParams = (deleteParams) => {
    const searchParams = new URLSearchParams(location.search);
    Object.entries(deleteParams).forEach(([key, value]) =>
      searchParams.delete(key)
    );
    navigate(`${location.pathname}?${searchParams}`);
  };

  const delayedSearch = useCallback(
    _.debounce((q) => {
      navigateWithParams({ q });
    }, 1500),
    []
  );

  useEffect(() => {
    if (search) {
      delayedSearch(search);
    } else {
      navigateDeleteParams({ q: null });
    }
  }, [search]);

  useEffect(() => {
    dispatch(getCatWithProducts());
  }, []);

  useEffect(() => {
    if (params.tableId) {
      dispatch(setTableId(params.tableId));
    }
  }, [params.tableId]);

  const toggleDrawer = (open) => {
    if (!open) {
      // use settimeout to prevent flickering
      setTimeout(() => {
        setSelectedProduct(null);
        dispatch(clearProduct());
      }, 500);
    }
    setOpenDrawer(open);
  };

  const handleSelectProduct = (product) => {
    setSelectedProduct(product);
    dispatch(getProductBySlug(product.ProductName));
    setOpenDrawer(true);
  }

  return (
    <>
      <main className="flex flex-col global-px">
        <section className="flex-1 flex flex-col items-center gap-5 py-5">
          <img src={logo} alt="odacafe" className="h-40 object-contain" />
          <div className="flex justify-center w-full">
            {isLoading ? (
              <Box
                px={2}
                width={1}
              >
                <Grid container>
                  {[...Array(12)].map((_, idx) => (
                    <Grid key={idx} item xs={6} md={2}>
                      <Skeleton
                        sx={{
                          width: { xs: 150, md: 220 },
                          height: { xs: 300, md: 300 },
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            ) : (
              <Grid container>
                {catWithProducts.map((item, idx) => (
                  <Grid key={idx} item xs={12} mb={3}>
                    <Typography variant="h6" component="h2">
                      {item.ProductCategory}
                    </Typography>
                    <Divider />
                    {JSON.parse(item.SubCategory).map((sub, idx) => (
                      <Grid container key={idx} spacing={1} alignItems='stretch'>
                        <Grid
                          item
                          xs={12}
                        >
                          <Box
                            flex={1}
                            className="bg-tertiary"
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              borderRadius: '8px',
                              height: '50px',
                            }}
                            my={1}
                          >
                            <Typography
                              className="text-primary"
                            >
                              {sub.ProductCategory}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} mb={1}>
                          {JSON.parse(sub.ProductListing).length > 0 ? (
                            <Grid
                              container
                              spacing={2}
                            >
                              {JSON.parse(sub.ProductListing).map((item, idx) => (
                                <Grid key={idx} item xs={6} md={2}>
                                  <Card
                                    onClick={() => handleSelectProduct(item)}
                                    sx={{
                                      position: 'relative', // Make the card position relative for absolute positioning of the button
                                      cursor: 'pointer',
                                      display: 'flex',
                                      flexDirection: 'column',
                                      borderRadius: '8px', // Increased border radius for smoother edges
                                      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // Soft shadow for depth
                                      transition: 'box-shadow 0.3s ease-in-out', // Smooth transition for hover effect
                                      '&:hover': {
                                        boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)', // Elevate the card on hover
                                      },
                                      backgroundColor: '#fff',
                                      // overflow: 'hidden', // Hide overflowing content
                                      width: '100%', // Ensure card takes full width
                                      height: '100%', // Ensure card takes full height
                                    }}
                                  >
                                    <CardMedia
                                      component="img"
                                      image={item.ProductImage}
                                      alt={item.ProductName}
                                      sx={{
                                        width: "100%",
                                        objectFit: 'contain',
                                      }}
                                    />
                                    <CardContent
                                      sx={{
                                        height: '100%',
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <Stack direction="column" spacing={1} alignItems="center">
                                        <p className="text-md font-normal text-center">{item.ProductName}</p>
                                        {item.ProductTag && (
                                          <Box>
                                            {item.ProductTag && item.ProductTag.split(',').map((tag, index) => (
                                              <Chip key={index} size="small" label={tag} variant="outlined" color="primary" sx={{ mr: 0.1, mb: 1 }} />
                                            ))}
                                          </Box>
                                        )}
                                        {item.discountPrice && (
                                          <Stack direction="row" spacing={1} alignItems="center">
                                            <Typography color="error" variant="h6" fontWeight='bold'>
                                              {item.discountPrice}
                                            </Typography>
                                            <Typography color="error" variant="body2">
                                              {item.discountType}
                                            </Typography>
                                          </Stack>
                                        )}
                                        <div
                                          dangerouslySetInnerHTML={{ __html: item.MinProductBasedPrice }}
                                          className="my-5"
                                        />
                                      </Stack>
                                    </CardContent>
                                  </Card>
                                </Grid>
                              ))}
                            </Grid>
                          ) : (
                            <div className="flex flex-col text-center">
                              <p className="text-black font-medium text-sm my-5">
                                No item
                              </p>
                            </div>
                          )}
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                ))}
              </Grid>
            )}
          </div>
        </section>
      </main>

      <SwipeableDrawer
        anchor={"right"}
        open={openDrawer}
        onClose={() => toggleDrawer(false)}
        onOpen={() => toggleDrawer(true)}
        disableSwipeToOpen
      >
        <Box
          sx={{
            width: window.innerWidth > 600 ? '30vw' : '100%',
            backgroundColor: theme.palette.background.paper,
            padding: theme.spacing(2),
          }}
          role="presentation"
        >
          <IconButton
            onClick={() => toggleDrawer(false)}
            sx={{
              position: 'absolute',
              top: theme.spacing(1),
              right: theme.spacing(1),
            }}
          >
            <Iconify icon="bx:bx-x" />
          </IconButton>
          {isEmpty(product) ? (
            <ProductSkeleton />
          ) : (
            <Product product={product} slug={selectedProduct?.ProductName} />
          )}
        </Box>
      </SwipeableDrawer>
    </>
  );
};

const Product = ({ product, slug }) => {
  const [selectedProduct, setSelectedProduct] = useState(null);

  const variations = selectedProduct && selectedProduct?.ProductVariation !== "null" ? JSON.parse(selectedProduct?.ProductVariation) : [];

  useEffect(() => {
    if (product.length > 0) {
      setSelectedProduct(product[0]);
    }
  }, [product]);

  const handleChange = (event, newProduct) => {
    setSelectedProduct(newProduct);
  }

  return (
    <Box my={5}>
      <p className="text-xl font-bold">
        {slug}
      </p>

      <p className="text-md font-light text-gray">
        {selectedProduct?.ProductCategory}
      </p>

      <Box mt={1}>
        <Image
          src={selectedProduct?.ProductImage}
          alt="Product"
          ratio="16/9"
          sx={{
            width: 1,
            objectFit: 'cover',
            borderRadius: 2,
            margin: 'auto',
          }}
          disabledEffect
        />

        {selectedProduct?.ProductTag && (
          <Box sx={{ my: 3 }}>
            {selectedProduct?.ProductTag.split(',').map((tag, index) => (
              <Chip key={index} label={tag} variant="outlined" color="primary" sx={{ mr: 0.1, mb: 1 }} />
            ))}
          </Box>
        )}

        <div
          dangerouslySetInnerHTML={{ __html: selectedProduct?.ProductDescription }}
          className="my-5"
        />

        <p className="text-end font-semibold my-2 text-lg">
          RM {selectedProduct?.BaseProductPrice}
        </p>

        <ToggleButtonGroup
          variant={'soft'}
          sx={{
            justifyContent: 'center',
            '& .MuiToggleButton-root.Mui-selected': {
              backgroundColor: '#8b5e3c',
              color: 'white',
            },
          }}
          fullWidth
          exclusive
          value={selectedProduct}
          onChange={handleChange}
        >
          {product.map((item, idx) => (
            <ToggleButton
              key={idx}
              value={item}
              disabled={item.ProductID === selectedProduct?.ProductID}
            >
              {item.ProductName}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>

        {!isEmpty(variations) ? variations.map((item, idx) => (
          <div key={idx} className="my-5">
            <p className='font-semibold text-lg'>{item.ProductVariation}</p>
            {JSON.parse(item.VariationOption).map((itm, idx) => (
              <div key={idx} className='flex justify-between items-center font-light'>
                <p>{itm.ProductVariationValue}</p>
                <p>RM {itm.ProductVariationPrice}</p>
              </div>
            ))}
            {idx !== variations.length - 1 && <div className='mb-5 mt-2'><Divider /></div>}
          </div>
        )) : (
          <div className="flex flex-col text-center">
            <p className="text-black font-medium text-sm my-5">
              No item
            </p>
          </div>)}
      </Box>
    </Box>
  );
}

const ProductSkeleton = () => {
  return (
    <Box my={5}>
      <Skeleton
        variant="text"
        width="100%"
        height={40}
      />
      <Skeleton
        variant="text"
        width="100%"
        height={20}
      />
      <Box mt={1}>
        <Skeleton
          variant="rectangular"
          width="100%"
          height={200}
        />
        <Skeleton
          variant="text"
          width="100%"
          height={20}
        />
        <Skeleton
          variant="text"
          width="100%"
          height={20}
        />
        <Skeleton
          variant="text"
          width="100%"
          height={20}
        />
      </Box>
    </Box>
  );
}