import React, { useEffect, useState } from 'react';

import { isEqual } from 'lodash';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ConfirmDialog from '../../components/confirm-dialog';
import Iconify from "../../components/iconify";

// @mui
import { Button } from "@mui/material";

import { clearError, clearPasswordReset, requestOTP } from '../../redux/slices/userInfo.slice';
import { useAuthContext } from '../Auth/useAuthContext';

function EditPassword({ isOpen, onClose }) {
  const { passwordReset, error } = useSelector((state) => state.userInfo);
  const { user } = useAuthContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [form, setForm] = useState({
    newpass: "",
    newpassconf: "",
  });
  const [err, setErr] = useState({
    newpass: "",
    newpassconf: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    if (passwordReset) {
      navigate("/auth/otp", { state: { hpNumber: user?.UserContactNo, password: form.newpass, type: 'resetPassword' } });
    }

    return () => {
      dispatch(clearPasswordReset());
    }
  }, [passwordReset]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }

    return () => {
      dispatch(clearError());
    }
  }, [error]);

  const formHandler = (e) => {
    return setForm((form) => {
      return {
        ...form,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleOnClose = () => {
    onClose();
    setForm({
      newpass: "",
      newpassconf: "",
    });
    setErr({
      newpass: "",
      newpassconf: "",
    });
  };

  const submitFormHandler = (e) => {
    e.preventDefault();
    const error = {
      newpass: "",
      newpassconf: "",
    };
    setErr(error);
    if (form.newpass.length < 1) {
      error.newpass = "Required";
    }
    if (form.newpassconf.length < 1) {
      error.newpassconf = "Required";
    }
    if (form.newpass.length < 8)
      error.newpass = "New password length minimum is 8";
    if (!isEqual(form.newpass, form.newpassconf))
      error.newpassconf = "Password and confirm password does not match";
    setErr(error);

    if (error.newpass === "" && error.newpassconf === "") {
      toast.promise(
        dispatch(requestOTP(user?.UserID, 'FORGETPASSWORD', user?.UserContactNo)),
        {
          loading: "Please wait a moment",
          error: (e) => {
            return e;
          },
        }
      );
    }
  };

  return (
    <ConfirmDialog
      open={isOpen}
      onClose={handleOnClose}
      title="Edit Password"
      content={
        <form onSubmit={submitFormHandler}>
          <div className="mb-6">
            <label
              htmlFor="newPassword"
              className="block mb-2 text-sm font-medium text-gray-700"
            >
              New Password
            </label>
            <div style={{ position: 'relative' }}>
              <input
                type={showPassword ? "text" : "password"}
                id="newPassword"
                name="newpass"
                className="bg-gray-100 border border-gray-400 text-black text-sm rounded-lg block w-full p-2.5"
                placeholder="Type new password"
                value={form.newpass}
                onChange={formHandler}
              />
              <Button
                onClick={() => {
                  setShowPassword(!showPassword)
                }}
                style={{
                  position: 'absolute',
                  top: '55%',
                  right: '0px',
                  transform: 'translateY(-50%)',
                  color: 'black',
                }}
              >
                <Iconify icon={showPassword ? "eva:eye-off-fill" : "eva:eye-fill"} width={30} />
              </Button>
            </div>
            <p className="mt-2 text-sm text-red-600 ">
              <span className="font-medium">{err.newpass}</span>
            </p>
          </div>
          <div className="mb-6">
            <label
              htmlFor="newPasswordConf"
              className="block mb-2 text-sm font-medium text-gray-700"
            >
              New Password Confirmation
            </label>
            <div style={{ position: 'relative' }}>
              <input
                type={showConfirmPassword ? "text" : "password"}
                id="newPasswordConf"
                name="newpassconf"
                className="bg-gray-100 border border-gray-400 text-black text-sm rounded-lg block w-full p-2.5"
                placeholder="Type again for confirmation"
                value={form.newpassconf}
                onChange={formHandler}
              />
              <Button
                onClick={() => {
                  setShowConfirmPassword(!showConfirmPassword)
                }}
                style={{
                  position: 'absolute',
                  top: '55%',
                  right: '0px',
                  transform: 'translateY(-50%)',
                  color: 'black',
                }}
              >
                <Iconify icon={showConfirmPassword ? "eva:eye-off-fill" : "eva:eye-fill"} width={30} />
              </Button>
            </div>
            <p className="mt-2 text-sm text-red-600 ">
              <span className="font-medium">{err.newpassconf}</span>
            </p>
          </div>
        </form>
      }
      action={
        <button
          className="bg-primary text-tertiary p-3 rounded-lg text-sm font-medium disabled:bg-gray-400 disabled:cursor-not-allowed"
          type="submit"
          onClick={submitFormHandler}
        >
          Confirm
        </button>
      }
    />
  );
}

export default EditPassword;
