/* eslint-disable react/prop-types */
import React, {
  useEffect,
  useState,
} from 'react';
import toast from 'react-hot-toast';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  useNavigate,
  useParams
} from 'react-router-dom';
// @mui
import {
  Box,
  Button,
  Divider,
  Stack,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';
import productPlaceholder from '../../assets/images/placeholder-image.webp';
import Header from '../../components/Header';
import Iconify from '../../components/iconify';
import { addItemToCart, clearProduct, getProductBySlug } from '../../redux/slices/product.slice';
import useDocumentTitle from '../../utils/documentTitle';
import { Loading, NotFound } from './components';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import FormProvider, { RHFMultiCheckbox, RHFRadioGroup } from '../../components/react-hook-form';
// auth
import { useAuthContext } from '../Auth/useAuthContext';
import { isEmpty } from 'lodash';

function ProductDetail() {
  const [notFound, setNotFound] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const { slug } = useParams();
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { product, isLoading, error, cart } = useSelector((state) => state.product);
  const { tableId } = useSelector((state) => state.order);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [defaultValues, setDefaultValues] = useState({});
  const [schema, setSchema] = useState({});

  const methods = useForm({
    resolver: selectedProduct?.ProductVariation !== "null" ? yupResolver(schema) : undefined,
    defaultValues: selectedProduct?.ProductVariation !== "null" ? defaultValues : undefined,
  });

  const {
    watch,
    handleSubmit,
    unregister,
    resetField,
    reset,
    formState: { isSubmitting, errors, fields },
  } = methods;

  useEffect(() => {
    dispatch(getProductBySlug(slug));

    return () => {
      dispatch(clearProduct());
    }
  }, []);

  useEffect(() => {
    if (product.length > 0) {
      notFound && setNotFound(false);
      setSelectedProduct(product[0]);
    } else {
      setNotFound(true);
    }
  }, [product]);

  useEffect(() => {
    if (selectedProduct && selectedProduct?.ProductVariation !== "null") {
      setDefaultValues(() => {
        const defaultValues = {};
        JSON.parse(selectedProduct.ProductVariation).forEach((item) => {
          defaultValues[item.ProductVariation] = item.RequiredIndicator === 1 ? "" : [];
        });
        return defaultValues;
      });

      setSchema(() => {
        const schema = {};
        JSON.parse(selectedProduct.ProductVariation).forEach((item) => {
          schema[item.ProductVariation] = item.RequiredIndicator === 1 ? Yup.string().required() : Yup.array();
        });
        return Yup.object().shape(schema);
      });
    } else {
      setDefaultValues({});
      setSchema({});
      const allFields = fields ? Object.keys(fields) : [];
      unregister(allFields);
    }

    return () => {
      reset();
      resetField();
    }
  }, [selectedProduct]);

  const countIncrement = () => {
    setQuantity((prev) => {
      if (prev + 1 > 100) return prev;
      return prev + 1;
    });
  };
  const countDecrement = () => {
    setQuantity((prev) => {
      if (prev - 1 < 1) return prev;
      return prev - 1;
    });
  };

  const handleAddToCart = async (data) => {
    const dataAsArray = Object.values(data);
    const newItem = {
      id: cart.length + 1,
      mainCategoryId: selectedProduct.MainCategoryID,
      productId: selectedProduct.ProductID,
      productName: selectedProduct.ProductName,
      img: !isEmpty(JSON.parse(selectedProduct.ProductImages)) ? JSON.parse(selectedProduct.ProductImages)[0].ProductMediaUrl : productPlaceholder,
      variation: dataAsArray,
      qty: quantity,
      basePrice: selectedProduct.BaseProductPrice,
      user: user || null,
      discountPrice: 0,
    };
    dispatch(addItemToCart(newItem));

    toast.success("Item added to cart");
    navigate(-1);
  };

  const handleChange = (event, value) => {
    setSelectedProduct(value);
    setQuantity(1);
  };

  const Detail = (props) => {
    if (!props.data) return null;

    const { ProductID, ProductName, ProductDescription, ProductImages, ProductVariation, BaseProductPrice, isDailyAvailable } = props.data;

    const images = ProductImages ? JSON.parse(ProductImages) : [];

    const variations = ProductVariation !== "null" ? JSON.parse(ProductVariation) : [];

    useDocumentTitle(slug);

    const variationCost = variations.length > 0 ? variations.reduce((acc, item) => {
      const selected = watch(item.ProductVariation);
      if (typeof selected === 'string') {
        const selectedVariation = JSON.parse(selected);
        return acc + selectedVariation.ProductVariationPrice;
      } else if (Array.isArray(selected)) {
        return acc + selected.reduce((acc, itm) => {
          const selectedVariation = JSON.parse(itm);
          return acc + selectedVariation.ProductVariationPrice;
        }, 0);
      }
      return acc;
    }, 0) : 0;

    return (
      <main className="global-px py-3">
        <Box>
          <Button
            variant='text'
            onClick={() => navigate(-1)}
            startIcon={<Iconify icon="carbon:chevron-left" />}
          >
            Back
          </Button>
        </Box>
        <section className="flex my-10 gap-16 flex-col md:flex-row">
          <aside className="flex-1 flex flex-col items-center justify-between gap-10">
            <img
              src={images.length > 0 ? images[0].ProductMediaUrl : productPlaceholder}
              alt={ProductName}
              className="aspect-square object-cover rounded-full w-64"
            />
          </aside>
          <FormProvider
            methods={methods}
            onSubmit={(e) => {
              e.preventDefault()
              handleSubmit(handleAddToCart)();
            }}
          >
            <aside className="flex-1 flex flex-col gap-5 justify-between">
              <p className="font-bold text-3xl uppercase w-full text-center mb-2">
                {slug}
              </p>
              {!ProductDescription ? (
                <p className="text-gray-700 text-lg md:min-h-[200px] font-normal">
                  This product does not have a description yet.
                </p>
              ) : (
                <div dangerouslySetInnerHTML={{ __html: ProductDescription }} />
              )}
              <Divider />

              <ToggleButtonGroup
                variant={'soft'}
                sx={{
                  justifyContent: 'center',
                  '& .MuiToggleButton-root.Mui-selected': {
                    backgroundColor: '#8b5e3c',
                    color: 'white',
                  },
                }}
                exclusive
                value={selectedProduct}
                onChange={handleChange}
              >
                {product.map((item, idx) => (
                  <ToggleButton
                    key={idx}
                    value={item}
                    disabled={item.ProductID === ProductID}
                  >
                    {item.ProductName}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>

              <Box mb={4}>
                {variations.map((item, idx) => (
                  <div key={idx}>
                    <p className='font-semibold text-lg'>
                      {item.ProductVariation}
                      &nbsp;
                      <span className='font-light text-sm'>{item.RequiredIndicator === 0 ? '(Optional)' : ''}</span>
                    </p>
                    {item.SelectionType === "Radio" ? (
                      <RHFRadioGroup
                        key={idx}
                        name={item.ProductVariation}
                        options={JSON.parse(item.VariationOption).map((itm) => {
                          return {
                            value: JSON.stringify(itm),
                            label: (
                              <Stack justifyContent="space-between" alignItems="center" direction="row">
                                <p>{itm.ProductVariationValue}</p>
                                <p>+ RM {itm.ProductVariationPrice}</p>
                              </Stack>
                            ),
                            isEnable: true,
                          }
                        })}
                      />
                    ) : (
                      <RHFMultiCheckbox
                        name={item.ProductVariation}
                        value={watch(item.ProductVariation)}
                        options={JSON.parse(item.VariationOption).map((itm) => {
                          return {
                            value: JSON.stringify(itm),
                            label: (
                              <Stack justifyContent="space-between" alignItems="center" direction="row">
                                <p>{itm.ProductVariationValue}</p>
                                <p>RM {itm.ProductVariationPrice}</p>
                              </Stack>
                            ),
                            isEnable: true,
                          }
                        })}
                      />
                    )}
                    {idx !== variations.length - 1 && <div className='mb-5 mt-2'><Divider /></div>}
                  </div>
                ))}
              </Box>

              <div className="flex justify-between items-center">
                <div className="custom-number-input h-10 w-32">
                  <div className="flex flex-row h-10 w-full rounded-lg relative bg-transparent mt-1v text-quartenary font-bold">
                    <button
                      type='button'
                      onClick={countDecrement}
                      className="bg-white h-full w-20 rounded-l cursor-pointer outline-none border-gray-400 border-2 border-r-0"
                    >
                      <span className="m-auto text-xl">−</span>
                    </button>
                    <input
                      type="number"
                      className="outline-none font-normal focus:outline-none text-center w-full bg-white text-md md:text-basecursor-default flex items-center border-gray-400 border-2"
                      name='quantity'
                      value={quantity}
                      onChange={(e) => setQuantity(e.target.value)}
                      min="1"
                    />
                    <button
                      type='button'
                      onClick={countIncrement}
                      className="bg-white h-full w-20 rounded-r cursor-pointer border-gray-400 border-2 border-l-0"
                    >
                      <span className="m-auto text-xl">+</span>
                    </button>
                  </div>
                </div>
                <p className="font-bold text-xl">
                  RM {((variationCost + BaseProductPrice) * quantity).toFixed(2)}
                </p>
              </div>
              <button
                type='submit'
                className="mt-4 block bg-primary text-white font-bold text-lg py-4 rounded-xl disabled:btn-disabled"
                disabled={isSubmitting || isDailyAvailable === 0}
              >
                Add to Cart
              </button>
            </aside>
          </FormProvider>
        </section>
      </main>
    );
  };

  return (
    <>
      <Header />
      {isLoading ? (
        <Loading />
      ) : notFound && error ? (
        <NotFound tableId={tableId} />
      ) : (
        <Detail data={selectedProduct} />
      )}
    </>
  );
}
export default ProductDetail;
