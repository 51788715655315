import { combineReducers } from "redux";
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import cartSlice from "./cart.slice";
import contextSlice from "./context.slice";
import profileSlice from "./profile.slice";
import uinfoSlice from "./userInfo.slice";
import productSlice from "./product.slice";
import orderSlice from "./order.slice";

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

export const orderPersistConfig = {
  key: 'order',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['tableId'],
};

const reducers = combineReducers({
  userInfo: uinfoSlice,
  profile: profileSlice,
  cart: cartSlice,
  context: contextSlice,
  product: persistReducer(productPersistConfig, productSlice),
  order: persistReducer(orderPersistConfig, orderSlice),
});

export default reducers;
