import React from 'react';

import productPlaceholder from '../../assets/images/placeholder-image.webp';
import {
  n_f
} from '../../utils/helpers';

const DetailView = ({ data }) => {
  const dataDetail = data || [];

  return (
    <aside className="flex-[2_2_0%] space-y-3">
      <p className="font-semibold">Products</p>
      <div className="flex flex-col h-72 overflow-y-scroll pr-2 gap-3">
        {dataDetail.map((item) => {
          const variations = JSON.parse(item.OrderProductCustomization);

          return (
            <div
              key={item.id}
              className="flex justify-between text-sm md:text-base gap-3"
            >
              <div>
                <div className="avatar">
                  <div className="w-16 rounded-xl">
                    <img
                      src={
                        item.ProductImage
                          ? item.ProductImage
                          : productPlaceholder
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="flex-1">
                <p className="font-medium">
                  {item.ProductName} x{item.ProductQuantity}
                </p>
                {variations.map((variation) => (
                  <p key={variation.CustomizationDetailsID} className="text-xs text-black/50">
                    {variation.CustomizationType}: {variation.CustomizationDetails}
                  </p>
                ))}
              </div>
              <div className="">
                <p className="">RM {n_f(item.ProductPrice)}</p>
              </div>
            </div>
          )
        })}
      </div>
    </aside>
  )
}

export default DetailView;