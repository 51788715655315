import React, { useEffect } from 'react';
import { Html5Qrcode } from 'html5-qrcode';
import Header from '../components/Header';
import { useNavigate } from 'react-router-dom';
import { setTableId } from '../redux/slices/order.slice';
import { useDispatch } from 'react-redux';

// @mui
import { Box, Button } from '@mui/material';
import Iconify from '../components/iconify';

const QRScanPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const height = document.body.offsetHeight
  const width = document.body.offsetWidth

  let html5QrCode;

  useEffect(() => {
    if (!html5QrCode?.getState()) {
      html5QrCode = new Html5Qrcode('scan-render');
      html5QrCode.start(
        { facingMode: "environment" },
        {
          fps: 10,
          qrbox: 250,
          zoom: 1,
          aspectRatio: 1.0,
        },
        (decodedText) => {
          if (decodedText) {
            html5QrCode.stop();
            const tableId = decodedText.split('/')[decodedText.split('/').length - 1];
            dispatch(setTableId(tableId));
            navigate(`/products/${tableId}`);
          }
        },
        (errorMessage) => {
          console.log(errorMessage)
        })
        .catch((err) => {
          console.log(err)
          html5QrCode.stop();
        });
    }

    return () => {
      if (html5QrCode.isScanning) {
        html5QrCode.stop();
      }
    }
  }, []);

  return (
    <>
      <Header />

      <div className="global-px py-3">
        <Box>
          <Button
            variant='text'
            onClick={() => {
              html5QrCode.stop();
              navigate(-1);
            }}
            startIcon={<Iconify icon="carbon:chevron-left" />}
          >
            Back
          </Button>
        </Box>
        <section className='flex flex-col justify-center text-center gap-5'>
          <h1 className='font-bold text-xl mt-5 text-primary'>Scan QR Code</h1>
          <p className='font-light'>Scan the QR code on the table</p>
          <div id="scan-render">
            {/* The QR code scanner will be rendered here */}
          </div>
        </section>
      </div>
    </>
  );
};

export default QRScanPage;