import React, { useContext, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import {
  Link,
  NavLink,
  Navigate,
  createSearchParams,
  useNavigate,
} from "react-router-dom";
import burgerIcon from "../assets/icons/burger-menu-left.svg";
// google
import { googleLogout } from '@react-oauth/google';
// navs
import navs from "../navigation/navs.js";
import navsWithoutHomeAbout from "../navigation/navsWithoutHomeAbout.js";
import chatIcon from "../assets/icons/chat.svg";
import placeholderProfile from "../assets/images/placeholder-profile.jpg";
import Iconify from "./iconify";
import logo from "../assets/oda.png";
// import logo from "../assets/oda.png";

// redux
import { useDispatch } from "react-redux";
import { clearCart } from "../redux/slices/product.slice.js";
import { useAuthContext } from "../pages/Auth/useAuthContext.js";
import withSearchParams from "../utils/wrappers/withSearchParams.js";
import Logout from "./Logout";
import Sidebar from "./Sidebar";
import { isMobile } from "../utils/helpers.js";

const Navigation = () => {
  const ctx = useContext(MyContext);
  return (
    <Sidebar
      customBurgerIcon={false}
      isOpen={ctx.isMenuOpen}
      onStateChange={(state) => ctx.stateChangeHandler(state)}
    />
  );
};

const Header = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isNavbarOpen, setNavbarOpen] = useState(false);
  const dropdownRef = useRef(null);
  const searchRef = useRef(null);
  const dispatch = useDispatch();
  const { user, logout } = useAuthContext();
  const navigate = useNavigate();
  const selectedNavs = !isMobile ? navs : navsWithoutHomeAbout;

  const toggleDropdown = () => {
    setDropdownOpen((prevState) => !prevState);
  };

  const toggleNavbar = () => {
    setNavbarOpen((prevState) => !prevState);
  };

  const limitCharacters = (str) => {
    if (str.length > 20) {
      // ...
    }
    return str;
  };

  const logoutHandler = async () => {
    toast.dismiss();
    googleLogout();
    const resp = await logout(user?.UserID);
    if (resp) {
      navigate("/");
      dispatch(clearCart());
    } else {
      toast.error("Something went wrong, please reload your page!");
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      // ...
    }
  };

  const handleClickOutsideSearch = (event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      // ...
    }
  };

  return (
    // render JSX here
    <>
      <Logout />
      <div
        className={`${isNavbarOpen ? "translate-x-0" : "translate-x-full"
          } fixed top-0 left-0 w-full h-full bg-black opacity-50 z-[45] transition-opacity duration-300 ease-in-out`}
        onClick={toggleNavbar}
      ></div>
      <div
        className={`${isNavbarOpen ? "translate-x-0" : "translate-x-full"
          } transform h-full w-80 bg-white fixed top-0 right-0 z-[60] transition-transform duration-300 ease-in-out`}
      >
        <Sidebar onClose={toggleNavbar} />
      </div>
      <header className="sticky top-0 z-40 backdrop-blur-xl border-b-2 border-gray-100 bg-secondary">
        <div className=" flex global-px justify-between items-center">
          <div className="py-5 md:py-8 font-bold">
            <Link to="/" className=" flex flex-row justify-center gap-2">
              <img src={logo} alt="logo" width="30px" className="img-fluid" style={{ objectFit: "contain" }} />
              <h1 className="text-xl font-bold">ODA</h1>
            </Link>
          </div>
          <div className="navbar-burger select-none cursor-pointer lg:hidden py-4 flex gap-7 flex-row items-center">
            <button onClick={toggleNavbar}>
              <img
                src={burgerIcon}
                width="30px"
                className="aspect-square"
                alt=""
              />
            </button>
          </div>
          <nav className="py-6 hidden lg:flex flex-row gap-8 justify-center">
            {selectedNavs.map((nav, idx) => (
              <li className="list-none" key={idx}>
                <NavLink
                  to={nav.path}
                  className={({ isActive }) =>
                    isActive ? "font-bold text-primary" : ""
                  }
                >
                  {nav.name}
                </NavLink>
              </li>
            ))}
          </nav>
          {user ? (
            <div className="flex-row gap-10 hidden lg:flex select-none py-4 items-center">
              <div
                className="relative flex items-center my-auto"
                ref={dropdownRef}
                onClick={toggleDropdown}
              >
                <div className=" flex items-center  cursor-pointer">
                  <div className="avatar">
                    <div className="w-9 rounded-full">
                      <img
                        src={
                          user?.UserProfileImage
                            ? user?.UserProfileImage
                            : placeholderProfile
                        }
                      />
                    </div>
                  </div>
                  <svg
                    className="w-4 h-4 ml-2"
                    aria-hidden="true"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    ></path>
                  </svg>
                </div>
                <div
                  className={`dropdown ${isDropdownOpen
                    ? "transition duration-300 ease-in-out opacity-100 transform -translate-y-6"
                    : "transition duration-200 ease-in-out opacity-0 transform -translate-y-10 invisible"
                    }`}
                >
                  {isDropdownOpen && (
                    <nav className="absolute list-none bg-white rounded-lg shadow-md border-1 border-gray-200 flex flex-col right-0 top-10 py-2 divide-y-1 transition-all duration-200 transform origin-top-right min-w-[14rem] z-[999]">
                      <div className="px-4 py-1">
                        <p>Signed in as</p>
                        <p className="font-bold py-2">
                          {user?.UserEmailAddress}
                        </p>
                      </div>
                      <div className="py-1">
                        <NavLink
                          className="block px-4 py-2 hover:bg-gray-100  duration-200"
                          to="/profile/"
                        >
                          Profile
                        </NavLink>
                      </div>
                      {Number(user?.UserTypeID) === 1 || Number(user?.UserTypeID) === 2 && (
                        <div className="py-1">
                          <NavLink
                            className="block px-4 py-2 hover:bg-gray-100  duration-200"
                            to="/admin"
                          >
                            Admin Dashboard
                          </NavLink>
                          <NavLink
                            className="block px-4 py-2 hover:bg-gray-100  duration-200"
                            to="/manage-order"
                          >
                            Manage Order
                          </NavLink>
                          <NavLink
                            className="block px-4 py-2 hover:bg-gray-100  duration-200"
                            to="/products/new"
                          >
                            Add Product
                          </NavLink>
                          <NavLink
                            className="block px-4 py-2 hover:bg-gray-100  duration-200"
                            to="/promo/new"
                          >
                            Add Promo
                          </NavLink>
                        </div>
                      )}
                      <div className="py-1">
                        <a
                          className="block px-4 py-2 hover:bg-gray-100 duration-200 cursor-pointer"
                          onClick={logoutHandler}
                        >
                          Sign out
                        </a>
                      </div>
                    </nav>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="hidden lg:flex flex-row gap-3 items-center select-none py-6">
              <Link to="/auth/login" className="mr-3 font-semibold">
                Sign in
              </Link>
              <Link to="/auth/register">
                <button className="rounded-[25px] border-primary border-2 px-5 text-primary font-semibold py-2 hover:bg-primary hover:text-tertiary duration-300">
                  Sign Up
                </button>
              </Link>
            </div>
          )}
        </div>
      </header>
    </>
  );
};

export default withSearchParams((Header));