import _ from "lodash";
import { ServerConfiguration } from "./serverConfig";

export const emailRegex =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g;

export const passRegex = /^(?=.*[0-9])(?=.*[a-z]).{8,}$/g;

export const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/g;

export const n_f = (number) => {
  if (!number || isNaN(number)) return 0;
  return number.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

export const short_n_f = (number) => {
  if (isNaN(number)) {
    throw new Error("Input isn't number");
  }

  const isNegative = number < 0;
  const absoluteNumber = Math.abs(number);

  const abbreviations = ["", "K", "M", "B", "T", "Q"];
  const base = 1000;

  if (absoluteNumber < base) {
    return isNegative ? `-${absoluteNumber}` : absoluteNumber.toString();
  }

  const exponent = Math.floor(Math.log10(absoluteNumber) / Math.log10(base));
  const abbreviatedNumber = (absoluteNumber / Math.pow(base, exponent)).toFixed(
    2
  );

  return (isNegative ? "-" : "") + abbreviatedNumber + abbreviations[exponent];
};

export function formatDateTime(dateTimeString) {
  const dateTime = new Date(dateTimeString);

  const year = dateTime.getFullYear();
  const month = String(dateTime.getMonth() + 1).padStart(2, "0");
  const day = String(dateTime.getDate()).padStart(2, "0");

  const hours = String(dateTime.getHours()).padStart(2, "0");
  const minutes = String(dateTime.getMinutes()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}`;
}

export const getEmailUsername = (email) => {
  if (!email || email === "") return "Anon";
  const username = _.head(_.split(email, "@"));
  return username;
};

export const uploader = async (files, filenames, directory, ID) => {
  const formData = new FormData();
  formData.append("Directory", directory);
  formData.append("ID", ID);

  for (var j = 0; j < files.length; j++) {
    formData.append("upload[]", files[j]);
    formData.append("fileName[]", filenames[j]);
  }
  const config = { headers: { 'Content-Type': 'multipart/form-data' } };
  const resp = await axios.post(ServerConfiguration.uploadUrl, formData, config)
  try {
    return resp.status == 200;
  }
  catch (err) {
    console.log(err)
    return false
  }
}

export const isMobile = window.innerWidth < 768;