import React, { useEffect, useState } from "react";

import toast from "react-hot-toast";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import notfoundImage from "../../assets/images/empty-box.svg";
import loadingImage from "../../assets/images/loading.svg";
import icon from "../../assets/oda.png";
import { resetPass, verifyResetPass } from "../../utils/dataProvider/auth";

function ResetPass() {
  const [error, setError] = useState({
    password: "",
    confirmPassword: "",
  });
  const [pass, setPass] = useState({
    password: "",
    confirmPassword: "",
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  function handleChange(e) {
    return setPass({ ...pass, [e.target.name]: e.target.value });
  }

  useEffect(() => {
    setIsLoading(true);
    const controller = new AbortController();
    verifyResetPass(
      searchParams.get("verify"),
      searchParams.get("code"),
      controller
    )
      .then((res) => {
        setIsLoading(false);
      })
      .catch((res) => {
        setIsLoading(false);
      });
  }, []);
  const Loading = (props) => {
    return (
      <main className="h-[50vh] flex items-center justify-center">
        <div>
          <img src={loadingImage} alt="Loading..." />
        </div>
      </main>
    );
  };
  const NotFound = (props) => {
    return (
      <main className="h-[80vh] flex flex-col items-center justify-center">
        <img src={notfoundImage} alt="Not Found" className="w-72" />
        <p>The link has expired</p>
      </main>
    );
  };

  function resetPassHandler(e) {
    e.preventDefault(); // preventing default submit
    toast.dismiss(); // dismiss all toast

    const valid = { password: "", confirmPassword: "" };

    if (pass.password.length < 8) {
      valid.password = "Password must be 8 character or higher";
    } else if (!/^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]+$/.test(pass.password)) {
      valid.password = "Password must alphanumeric";
    }

    if (pass.password !== pass.confirmPassword) {
      valid.confirmPassword = "Password not match";
    }

    setError({
      password: valid.password,
      confirmPassword: valid.confirmPassword,
    });
    if (!isLoading && valid.password === "" && valid.confirmPassword === "") {
      setIsLoading(true);
      e.target.disabled = true;
      const controller = new AbortController();
      toast.promise(
        resetPass(
          searchParams.get("verify"),
          searchParams.get("code"),
          pass,
          controller
        ).then((res) => {
          e.target.disabled = false;
          setIsLoading(false);
          navigate("/auth/login", { replace: true });
          return res.data;
        }),
        {
          loading: "Please wait a moment",
          success: "The new password has been set successfully",
          error: (err) => {
            e.target.disabled = false;
            setIsLoading(false);
            if (err.response) return err.response?.data?.msg;
            return err.message;
          },
        }
      );
    }
  }
  return (
    <>
      <header className="flex float-right mb-10">
        <div className="text-lg md:text-xl font-semibold text-primary">
          New Password
        </div>
      </header>
      {isLoading ? (
        <Loading />
      ) : (
        <section className="mt-16">
          <form
            action=""
            method="post"
            className="space-y-4 md:space-y-6 relative"
          >
            <div className="space-y-5">
              <h2 className="font-bold text-3xl">
                Reset your password
              </h2>
            </div>
            <div>
              <p className="font-normal">
                You have requested a link to reset your password, please enter a
                new password to access your account
              </p>
              <div className="gap-1 flex flex-col">
                <input
                  type="password"
                  name="password"
                  id="password"
                  className={
                    `border-gray-400 border-2 rounded-2xl p-3 w-full mt-2` +
                    (error.password !== "" ? " border-red-500" : "")
                  }
                  placeholder="Enter your new password"
                  value={pass.password}
                  onChange={handleChange}
                />
                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1 h-4">
                  {error.password !== "" ? error.password : ""}
                </span>

                <input
                  type="password"
                  name="confirmPassword"
                  id="confirmPassword"
                  className={
                    `border-gray-400 border-2 rounded-2xl p-3 w-full mt-2` +
                    (error.confirmPassword !== "" ? " border-red-500" : "")
                  }
                  placeholder="Confirm your new password"
                  value={pass.confirmPassword}
                  onChange={handleChange}
                />
                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1 h-4">
                  {error.confirmPassword !== "" ? error.confirmPassword : ""}
                </span>
              </div>
              <button
                type="submit"
                className="w-full text-tertiary bg-primary focus:ring-4 focus:outline-none focus:ring-primary-300 font-bold rounded-2xl text-lg p-3 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 shadow-xl mt-4"
                onClick={resetPassHandler}
              >
                Send
              </button>
            </div>
          </form>
        </section>
      )}
    </>
  );
}

export default ResetPass;
