import React, { useEffect, useState } from "react";

import { now } from "lodash";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

// @mui
import { Button } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { clearError, requestOTP, clearPasswordReset } from "../../redux/slices/userInfo.slice";
import useDocumentTitle from "../../utils/documentTitle";

// utils
import { passRegex, phoneRegex } from "../../utils/helpers";

// components
import Iconify from "../../components/iconify";

const ForgotPass = () => {
  useDocumentTitle("Forgot Password");

  const navigate = useNavigate();
  const [form, setForm] = useState({
    hpNumber: "",
    password: "",
    confirmPassword: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errorMsg, setErrorMsg] = useState({
    hpNumber: "",
    password: "",
    confirmPassword: "",
  });
  const dispatch = useDispatch();
  const { passwordReset, isLoading, error } = useSelector((state) => state.userInfo);

  useEffect(() => {
    if (passwordReset) {
      navigate("/auth/otp", { state: { hpNumber: form.hpNumber, password: form.password, userId: passwordReset.UserID, type: 'resetPassword' } });
    }

    return () => {
      dispatch(clearPasswordReset());
    }
  }, [passwordReset]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }

    return () => {
      dispatch(clearError());
    }
  }, [error]);

  function forgotPassHandler(e) {
    e.preventDefault(); // preventing default submit
    toast.dismiss(); // dismiss all toast

    const valid = { hpNumber: "", password: "", confirmPassword: "" };

    if (form.hpNumber.length < 1) {
      valid.hpNumber = "Input your phone number";
    } else if (!form.hpNumber.match(phoneRegex)) {
      valid.hpNumber = "Invalid phone number";
    }

    if (!form.password.match(passRegex)) {
      valid.password = "Password must be 8 character or higher";
    }

    if (form.password !== form.confirmPassword) {
      valid.confirmPassword = "Password not match";
    }

    setErrorMsg({
      hpNumber: valid.hpNumber,
      password: valid.password,
      confirmPassword: valid.confirmPassword,
    });

    if (valid.hpNumber === "" && valid.password === "" && valid.confirmPassword === "") {
      toast.promise(
        dispatch(requestOTP(0, 'FORGETPASSWORD', form.hpNumber)),
        {
          loading: "Please wait a moment",
          error: (e) => {
            return e;
          },
        }
      );
    } else {
      toast.error("Please input valid phone number!");
    }
  }

  function onChangeForm(e) {
    return setForm((form) => {
      return {
        ...form,
        [e.target.name]: e.target.value,
      };
    });
  }

  return (
    <>
      <header className="flex float-right mb-10">
        <div className="text-lg md:text-xl font-semibold text-primary">
          Forgot Password
        </div>
      </header>
      <section className="mt-16">
        <form
          action=""
          method="post"
          className="space-y-4 md:space-y-4 relative"
        >
          <div className="space-y-5">
            <p className="font-bold text-3xl">
              Forgot your password?
            </p>
            <p className="text-xl">
              Don’t worry, we got your back!
            </p>
          </div>
          <div>
            <label
              name="password"
              htmlFor="password"
              className="text-[#4F5665] font-bold"
            >
              Phone number :
            </label>
            <input
              type="text"
              name="hpNumber"
              id="hpNumber"
              className={
                `border-gray-400 border-2 rounded-2xl p-3 w-full mt-2` +
                (errorMsg.hpNumber !== "" ? " border-red-500" : "")
              }
              placeholder="Enter your phone number"
              value={form.hpNumber}
              onChange={onChangeForm}
            />
            <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1 h-4">
              {errorMsg.hpNumber !== "" ? errorMsg.hpNumber : ""}
            </span>
          </div>
          <div>
            <label
              name="password"
              htmlFor="password"
              className="text-[#4F5665] font-bold"
            >
              New Password :
            </label>
            <div style={{ position: 'relative' }}>
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                id="password"
                className={
                  `border-gray-400 border-2 rounded-2xl p-3 w-full mt-2` +
                  (errorMsg.password != "" ? " border-red-500" : "")
                }
                placeholder="Enter your password"
                value={form.password}
                onChange={onChangeForm}
              />
              <Button
                onClick={() => {
                  setShowPassword(!showPassword)
                }}
                style={{
                  position: 'absolute',
                  top: '55%',
                  right: '0px',
                  transform: 'translateY(-50%)',
                  color: 'black',
                }}
              >
                <Iconify icon={showPassword ? "eva:eye-off-fill" : "eva:eye-fill"} width={30} />
              </Button>
            </div>
            <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1 h-4">
              {errorMsg.password != "" ? errorMsg.password : ""}
            </span>
          </div>
          <div>
            <label
              name="confirmPassword"
              htmlFor="confirmPassword"
              className="text-[#4F5665] font-bold"
            >
              Confirm password :
            </label>
            <div style={{ position: 'relative' }}>
              <input
                type={showConfirmPassword ? "text" : "password"}
                name="confirmPassword"
                id="confirmPassword"
                className={
                  `border-gray-400 border-2 rounded-2xl p-3 w-full mt-2` +
                  (errorMsg.confirmPassword != "" ? " border-red-500" : "")
                }
                placeholder="Retype your password"
                value={form.confirmPassword}
                onChange={onChangeForm}
              />
              <Button
                onClick={() => {
                  setShowConfirmPassword(!showConfirmPassword)
                }}
                style={{
                  position: 'absolute',
                  top: '55%',
                  right: '0px',
                  transform: 'translateY(-50%)',
                  color: 'black',
                }}
              >
                <Iconify icon={showConfirmPassword ? "eva:eye-off-fill" : "eva:eye-fill"} width={30} />
              </Button>
            </div>
            <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1 h-4">
              {errorMsg.confirmPassword != "" ? errorMsg.confirmPassword : ""}
            </span>
          </div>
          <button
            type="submit"
            disabled={isLoading}
            className="w-full text-tertiary bg-primary focus:ring-4 focus:outline-none focus:ring-primary-300 font-bold rounded-2xl text-lg p-3 text-center  shadow-xl"
            onClick={forgotPassHandler}
          >
            Send
          </button>
        </form>
      </section>
      <button
        type="submit"
        className="w-full mt-2 text-primary focus:ring-4 focus:outline-none focus:ring-primary-300 font-bold rounded-2xl text-lg p-3 text-center "
        onClick={() => navigate(-1)}
      >
        Back
      </button>
    </>
  );
};

export default ForgotPass;
