import { createSlice } from '@reduxjs/toolkit';
// utils
import uploader from '../../utils/imgUploader';
import { ServerConfiguration } from '../../utils/serverConfig';
import { toast } from "react-hot-toast";
import { setUser } from '../../pages/Auth/utils';

// ----------------------------------------------------------------------

const url = ServerConfiguration.liveServerUrl

const projectId = 1

const shopId = 1;

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  passwordReset: null,
  registration: null,
  registerOTP: null,
  forgotPassword: null,
  updatedProfile: null,
};

const slice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    // Start Loading
    startLoading(state) {
      state.isLoading = true;
    },

    // has Error
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // Password Reset Success
    passwordResetSuccess(state, action) {
      state.isLoading = false;
      state.passwordReset = action.payload;
    },

    // Registration Success
    registrationSuccess(state, action) {
      state.isLoading = false;
      state.registration = action.payload;
    },

    // Register OTP
    registerOTP(state, action) {
      state.isLoading = false;
      state.registerOTP = action.payload;
    },

    // Get Forgot Password Success
    forgotPasswordSuccess(state, action) {
      state.isLoading = false;
      state.forgotPassword = action.payload;
    },

    // Change Profile Success
    changeProfileSuccess(state, action) {
      state.isLoading = false;
      state.updatedProfile = action.payload;
    },

    // Clear Error
    clearError(state) {
      state.error = null;
    },

    // Clear Password Reset
    clearPasswordReset(state) {
      state.passwordReset = null;
    },

    // Clear Register OTP
    clearRegisterOTP(state) {
      state.registerOTP = null;
    },
  },
});

// Reducer
export default slice.reducer;

// Action
export const {
  clearError,
  clearPasswordReset,
  clearRegisterOTP,
} = slice.actions;

// ----------------------------------------------------------------------

export function requestOTP(userId, type, validationField) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}User_SentOTPVerification?&USERID=${userId}&TYPE=${type}&VALIDATIONFIELD=${validationField}&PROJECTID=${projectId}`
      )
      const json = await response.json();
      if (json.Message) {
        dispatch(slice.actions.hasError(json.Message));
      } else {
        const data = JSON.parse(json)[0];
        if (data.ReturnVal === 1) {
          dispatch(slice.actions.passwordResetSuccess(JSON.parse(data.ReturnData)[0]));
        } else {
          dispatch(slice.actions.hasError(data.ReturnSqlError !== "" ? data.ReturnSqlError : data.ReturnMsg));
        }
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function registerVerification(userId, hpNumber, otp, token, type) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}User_Register_VerifyOTP?&USERID=${userId}&USERCONTACTNO=${hpNumber}&OTP=${otp}&TOKEN=${token}&TYPE=${type}`
      )
      const json = await response.json();
      if (json.Message) {
        dispatch(slice.actions.hasError(json.Message));
      } else {
        const data = JSON.parse(json)[0];
        if (data.ReturnVal === 1) {
          dispatch(slice.actions.registrationSuccess(JSON.parse(data.ReturnData)[0]));
        } else {
          dispatch(slice.actions.hasError(data.ReturnSqlError !== "" ? data.ReturnSqlError : data.ReturnMsg));
        }
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function registerWithOTP(hpNumber, password) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}User_Register_WithOTP?&USERCONTACTNO=${hpNumber}&PASSWORD=${password}&PROJECTID=${projectId}`
      )
      const json = await response.json();
      if (json.Message) {
        dispatch(slice.actions.hasError(json.Message));
      } else {
        const data = JSON.parse(json)[0];
        if (data.ReturnVal === 1) {
          toast.success(data.ReturnMsg);
          dispatch(slice.actions.registerOTP(JSON.parse(data.ReturnData)[0]));
        } else {
          dispatch(slice.actions.hasError(data.ReturnSqlError !== "" ? data.ReturnSqlError : data.ReturnMsg));
        }
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function resetPassword(hpNumber, otp, password) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}User_ForgetPassword_VerifyOTP?&USERCONTACTNO=${hpNumber}&PROJECTID=${projectId}&OTP=${otp}&PASSWORD=${password}`
      )
      const json = await response.json();
      if (json.Message) {
        dispatch(slice.actions.hasError(json.Message));
      } else {
        const data = JSON.parse(json)[0];
        if (data.ReturnVal === 1) {
          dispatch(slice.actions.forgotPasswordSuccess(JSON.parse(data.ReturnData)[0]));
        } else {
          dispatch(slice.actions.hasError(data.ReturnSqlError !== "" ? data.ReturnSqlError : data.ReturnMsg));
        }
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateProfile(userId, firstName, lastName, email, gender, hpNumber, dob, imageFile, fileName) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      if (imageFile) {
        const resp = await uploader([imageFile], [fileName], 'user', userId)
        if (!resp) {
          dispatch(slice.actions.hasError("Image upload failed"));
          return;
        }
      }

      const response = await fetch(
        `${url}User_UpdateProfile?&USERID=${userId}&FIRSTNAME=${firstName}&LASTNAME=${lastName}&USEREMAIL=${email}&USERGENDER=${gender}&USERCONTACTNO=${hpNumber}&USERDOB=${dob}&USERPROFILEIMAGE=${fileName}`
      )
      const json = await response.json();
      if (json.Message) {
        dispatch(slice.actions.hasError(json.Message));
      } else {
        const data = JSON.parse(json)[0];
        if (data.ReturnVal === 1) {
          dispatch(slice.actions.changeProfileSuccess(JSON.parse(data.ReturnData)[0]));
          setUser(data.ReturnData);
        } else {
          dispatch(slice.actions.hasError(data.ReturnSqlError !== "" ? data.ReturnSqlError : data.ReturnMsg));
        }
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}