import { createSlice } from '@reduxjs/toolkit';
// utils
import uploader from '../../utils/imgUploader';
import { ServerConfiguration } from '../../utils/serverConfig';
import { toast } from "react-hot-toast";

// ----------------------------------------------------------------------

const url = ServerConfiguration.liveServerUrl

const projectId = 1

const shopId = 1;

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  orderSubmission: null,
  tableId: null,
  paymentMethods: [],
  historyByUserId: [],
  historyByOrderId: [],
  historyByTableId: [],
};

const slice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    // Start Loading
    startLoading(state) {
      state.isLoading = true;
    },

    // has Error
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // Add Order Success
    addOrderSuccess(state, action) {
      state.isLoading = false;
      state.orderSubmission = action.payload;
    },

    // Get History By User Id Success
    getHistoryByUserIdSuccess(state, action) {
      state.isLoading = false;
      state.historyByUserId = action.payload;
    },

    // Set Table Id Success
    setTableId(state, action) {
      state.tableId = action.payload;
    },

    // Clear Error
    clearError(state) {
      state.error = null;
    },

    // Get Payment Methods Success
    getPaymentMethodsSuccess(state, action) {
      state.isLoading = false;
      state.paymentMethods = action.payload;
    },

    // Get History By Order Id Success
    getHistoryByOrderIdSuccess(state, action) {
      state.isLoading = false;
      state.historyByOrderId = action.payload;
    },

    // Get History By Table Id Success
    getHistoryByTableIdSuccess(state, action) {
      state.isLoading = false;
      state.historyByTableId = action.payload;
    },

    // Clear Table Id
    clearTableId(state) {
      state.tableId = null;
    },
  },
});

// Reducer
export default slice.reducer;

// Action
export const {
  clearError,
  setTableId,
  clearTableId,
} = slice.actions;

// ----------------------------------------------------------------------

export function submitOrder(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    return fetch(
      `${url}Order_AddOrderWithTableQR`
      , {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          USERMEMBERSHIP: data.userMembership,
          TABLEQRCODE: data.tableId,
          PROMOTIONCODEID: data.promotionCodeId,
          PAYMENTMETHODID: data.paymentMethodId,
          USERPAYMENTMETHODID: data.userPaymentMethodId,
          ORDERTOTALAMOUNT: data.orderTotalAmount,
          ORDERPAIDAMOUNT: data.orderPaidAmount,
          ORDERSHIPPINGFEEAMOUNT: data.orderShippingFeeAmount,
          ORDERTAXAMOUNT: data.orderTaxAmount,
          TRANSACTIONUUID: data.transactionUuid,
          PICKUPIND: data.pickupInd,
          ORDERORIGIN: data.orderOrigin,

          ORDERTYPE: data.orderType,
          PRODUCTID: data.productId,
          PRODUCTQUANTITY: data.productQuantity,
          PROMOTIONID: data.promotionId,
          PROMOTIONITEMID: data.promotionItemId,
          DETAILREMARK: data.detailRemark,

          CUSTOMIZATIONTYPEID: data.customizationTypeId,
          CUSTOMIZATIONDETAILSID: data.customizationDetailsId,
          DISCOUNTEDPRODUCTID: data.discountedProductId,

          DATA: JSON.stringify({
            USERMEMBERSHIP: data.userMembership,
            TABLEQRCODE: data.tableId,
            PROMOTIONCODEID: data.promotionCodeId,
            PAYMENTMETHODID: data.paymentMethodId,
            USERPAYMENTMETHODID: data.userPaymentMethodId,
            ORDERTOTALAMOUNT: data.orderTotalAmount,
            ORDERPAIDAMOUNT: data.orderPaidAmount,
            ORDERSHIPPINGFEEAMOUNT: data.orderShippingFeeAmount,
            ORDERTAXAMOUNT: data.orderTaxAmount,
            TRANSACTIONUUID: data.transactionUuid,
            PICKUPIND: data.pickupInd,
            ORDERORIGIN: data.orderOrigin,

            ORDERTYPE: data.orderType,
            PRODUCTID: data.productId,
            PRODUCTQUANTITY: data.productQuantity,
            PROMOTIONID: data.promotionId,
            PROMOTIONITEMID: data.promotionItemId,
            DETAILREMARK: data.detailRemark,

            CUSTOMIZATIONTYPEID: data.customizationTypeId,
            CUSTOMIZATIONDETAILSID: data.customizationDetailsId,
            DISCOUNTEDPRODUCTID: data.discountedProductId,
          }),
        })
      }
    )
      .then(response => response.json())
      .then(json => {
        if (json.Message) {
          dispatch(slice.actions.hasError(json.Message));
        } else {
          const data = json[0];
          if (data && data.ReturnVal === 1) {
            dispatch(slice.actions.addOrderSuccess(JSON.parse(data.ReturnData)[0]));
          } else {
            dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
          }
        }
      })
      .catch(error => dispatch(slice.actions.hasError(error)));
  };
}

export function getHistoryByUserId(trackingStatusId, userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Order_ViewOrderByUserID?TRACKINGSTATUS=${trackingStatusId}&USERID=${userId}&SHOPID=${shopId}`
      )
      const json = await response.json();
      if (json.Message) {
        dispatch(slice.actions.hasError(json.Message));
      } else {
        const data = JSON.parse(json)[0];
        if (data.ReturnVal === 1) {
          dispatch(slice.actions.getHistoryByUserIdSuccess(JSON.parse(data.ReturnData)));
        } else {
          dispatch(slice.actions.hasError(data.ReturnMsg));
        }
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPaymentMethods() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}General_ViewPaymentMethod`
      )
      const json = await response.json();
      if (json.Message) {
        dispatch(slice.actions.hasError(json.Message));
      } else {
        const data = JSON.parse(json)[0];
        if (data.ReturnVal === 1) {
          dispatch(slice.actions.getPaymentMethodsSuccess(JSON.parse(data.ReturnData)));
        } else {
          dispatch(slice.actions.hasError(data.ReturnMsg));
        }
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getHistoryByOrderId(orderId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Order_ViewOrderByOrderID?ORDERID=${orderId}`
      )
      const json = await response.json();
      if (json.Message) {
        dispatch(slice.actions.hasError(json.Message));
      } else {
        const data = JSON.parse(json)[0];
        if (data.ReturnVal === 1) {
          dispatch(slice.actions.getHistoryByOrderIdSuccess(JSON.parse(data.ReturnData)[0]));
        } else {
          dispatch(slice.actions.hasError(data.ReturnMsg));
        }
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getHistoryByTableId(tableId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Order_ViewOrderByShopAndTable?SHOPID=${shopId}&TABLEID=${tableId}`
      )
      const json = await response.json();
      if (json.Message) {
        dispatch(slice.actions.hasError(json.Message));
      } else {
        const data = JSON.parse(json)[0];
        if (data.ReturnVal === 1) {
          dispatch(slice.actions.getHistoryByTableIdSuccess(JSON.parse(data.ReturnData)));
        } else {
          dispatch(slice.actions.hasError(data.ReturnMsg));
        }
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}