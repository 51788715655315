export const applyDiscount = (state, user) => {
  state.discountArray = [];

  let count = 0;

  let discountPrice = 0;

  if (user?.MembershipTypeID === 1 || user?.MembershipTypeID === 2) {
    for (let i = 0; i < state.cart.length; i++) {
      let item = state.cart[i];

      if (item.mainCategoryId === 2) {
        for (let j = 0; j < item.qty && count <= user?.TotalTransaction; j++) {
          discountPrice = item.basePrice * (user?.MemberDiscount / 100);
          state.discountArray.push({ ...item, discountPrice: discountPrice, discountId: count + 1, qty: 1 });
          state.cart[i].discountPrice += discountPrice;
          count++;
        }
      }
      if (count >= user?.TotalTransaction) break;
    }
  } else if (user?.MembershipTypeID === 3) {
    for (let i = 0; i < state.cart.length; i++) {
      let item = state.cart[i];

      if (item.mainCategoryId === 2) {
        for (let j = 0; j < item.qty && count <= user?.TotalTransaction; j++) {
          discountPrice = item.basePrice * (user?.MemberDiscount / 100);
          state.discountArray.push({ ...item, discountPrice: discountPrice, discountId: count + 1, qty: 1 });
          count++;
        }
      }

      state.cart[i].discountPrice += discountPrice;
      if (count >= user?.TotalTransaction) break;
    }
  }

  return state;
}