import { styled } from '@mui/material/styles';
import { MuiOtpInput } from 'mui-one-time-password-input';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { clearError, registerVerification, resetPassword } from '../../redux/slices/userInfo.slice';


import { useAuthContext } from './useAuthContext';

const MuiOtpInputStyled = styled(MuiOtpInput)`
  display: flex;
  gap: 5px;
  max-width: 650px;
  margin-inline: auto;
`

function OTP(others) {
  return <MuiOtpInputStyled {...others} />
}

const OTPPage = () => {
  const [otp, setOTP] = useState('');
  const [countdown, setCountdown] = useState(60);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, login } = useAuthContext();
  const { state } = useLocation();
  const { registration, forgotPassword, error } = useSelector((state) => state.userInfo);

  useEffect(() => {
    if (registration) {
      toast.promise(
        login(state.hpNumber, state.password, '-'),
        {
          loading: () => {
            return "Please wait a moment";
          },
          success: (result) => {
            if (result.success) {
              navigate("/products");
              toast.success("Welcome to ODA!\nYou can order for now!", {
                icon: "👋",
                duration: 2000,
              });
            } else {
              toast.error("Incorrect phone number or password");
            }
          },

          error: (e) => {
            return "An error occurred while logging in";
          },
        }
      );
    }
  }, [registration])

  useEffect(() => {
    if (forgotPassword) {
      navigate("/auth/login");
      toast.success("Password changed successfully");
    }
  }, [forgotPassword]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }

    return () => {
      dispatch(clearError());
    }
  }, [error]);

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [countdown]);

  const getOTP = (value) => {
    if (value.length === 6) {
      if (state.type === 'resetPassword') {
        dispatch(resetPassword(state.hpNumber, value, state.password));
      } else {
        dispatch(registerVerification(state.userId, state.hpNumber, value, '-', 3));
      }
    };
  }

  const handleOTPChange = (value) => {
    setOTP(value);
    getOTP(value);
  };

  const handlePaste = (event) => {
    const pasteData = event.clipboardData.getData('text/plain');
    const newOTP = pasteData.replace(/\D/g, '').slice(0, 6);
    setOTP(newOTP);
    getOTP(newOTP);
  };

  const handleResendClick = () => {
    // TODO: Implement resend functionality
    setCountdown(60);
  };

  const isLoading = countdown > 0;

  return (
    <>
      <header className="flex justify-between mb-10">
        <div />
        <div className="text-lg md:text-xl font-semibold text-primary">
          OTP Verification
        </div>
      </header>
      <section className="mt-16">
        <form className="space-y-4 relative">
          <div className="space-y-5">
            <p className="font-bold text-3xl">
              Enter the OTP
            </p>
            <p className="text-xl">
              Please enter the OTP sent to your phone number
            </p>
          </div>
          <OTP
            value={otp}
            onChange={handleOTPChange}
            autoFocus
            length={6}
            mb={3}
            onPaste={handlePaste}
          />

          <button
            type="submit"
            disabled={isLoading}
            className={
              (isLoading
                ? "cursor-not-allowed bg-secondary text-gray-400"
                : "cursor-pointer bg-primary text-tertiary hover:bg-quartenary") +
              " w-full focus:ring-4 focus:outline-none focus:ring-primary-300 font-bold rounded-2xl text-base md:text-lg p-3 text-center  shadow-xl inline-flex items-center justify-center transition ease-in-out duration-150"
            }
            onClick={handleResendClick}
          >
            {isLoading ? (
              `Resend in ${countdown}s`
            ) : (
              "Resend"
            )}
          </button>
        </form>
      </section>

      <button
        className="w-full mt-3 text-primary focus:ring-4 focus:outline-none focus:ring-primary-300 font-bold rounded-2xl text-base md:text-lg p-3 text-center lg:mb-20"
        onClick={() => navigate(-1)}
      >
        Back
      </button>
    </>
  );
};

export default OTPPage;