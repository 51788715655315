import React from 'react';

import { NavLink } from 'react-router-dom';

import notfoundImage from '../../assets/images/empty-box.svg';

import Header from '../../components/Header';

export default function NotFound() {
  return (
    <>
      <Header />
      <main className="h-[80vh] flex flex-col items-center justify-center">
        <img src={notfoundImage} alt="Not Found" className="w-72" />
        <p>Page Not Found</p>
        <NavLink to="/" className="mt-5 p-3 bg-primary text-tertiary rounded-lg">
          Back to Home
        </NavLink>
      </main>
    </>
  );
}
