import React from 'react';

import Footer from '../components/Footer';
import Header from '../components/Header';

// assets
import beanIcon from '../assets/icons/bean.svg';
import checkCircle from '../assets/icons/check-circle.svg';
import placeIcon from '../assets/icons/place.svg';
import starIcon from '../assets/icons/star.svg';
import staffIcon from '../assets/icons/user.svg';
import loadingImage from '../assets/images/loading.svg';
import amazonLogo from '../assets/images/partners/amazon.svg';
import discordLogo from '../assets/images/partners/discord.svg';
import netflixLogo from '../assets/images/partners/netflix.svg';
import redditLogo from '../assets/images/partners/reddit.svg';
import spotifyLogo from '../assets/images/partners/spotify.svg';
import phProfile from '../assets/images/placeholder-profile.jpg';
import productImage1 from '../assets/images/product-1.webp';
import odaCafeImage from '../assets/images/oda-cafe.png';

// @mui
import { Box, Grid, Link } from '@mui/material';

// utils
import { n_f } from '../utils/helpers';

// google maps
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

const AboutUs = () => {
  const provide = [
    "O: Standing for Orbital, we represent a departure from the everyday, a journey to a unique coffee experience.",
    "D: Embracing Discovery, we're passionate about crafting innovative beverages and fostering a space for exploration.",
    "A: Creating a welcoming Atmosphere, our clean, white aesthetic evokes a sense of serenity and a modern spaceship ready for liftoff.",
  ];

  const reviews = [
    {
      name: "Foo Barr",
      text: "Wow... I am very happy to spend my whole day here. the Wi-fi is good, and the coffee and meals tho. I like it here!! Very recommended!",
    },
    {
      name: "Yessica Christy",
      text: "I like it because I like to travel far and still can make my day better just by drinking their Hazelnut Latte",
    },
    {
      name: "Kim Young Jou",
      text: "This is very unusual for my taste, I haven’t liked coffee before but their coffee is the best! and yup, you have to order the chicken wings, the best in town",
    },
  ];

  const mainData = [
    {
      id: 1,
      title: 'Beans',
      data: 9,
      icon: staffIcon,
    },
    {
      id: 2,
      title: 'Drinks',
      data: 12,
      icon: beanIcon,
    },
    {
      id: 3,
      title: 'Flavours',
      data: 30,
      icon: placeIcon,
    },
  ];

  const partnerLogos = [
    {
      id: 1,
      logo: netflixLogo,
    },
    {
      id: 2,
      logo: redditLogo,
    },
    {
      id: 3,
      logo: amazonLogo,
    },
    {
      id: 4,
      logo: discordLogo,
    },
    {
      id: 5,
      logo: spotifyLogo,
    },
  ];

  const favouriteProducts = [
    {
      id: 1,
      title: 'Hazelnut Latte',
      description: 'Hazelnut syrup with wanilla whipped cream and sliced banana on top',
      price: 25,
      image: productImage1,
    },
    {
      id: 2,
      title: 'Iced Coffee',
      description: 'Beef bacon, beef meat, chicken meat, mozarella, yellow cheese, eggs',
      price: 20,
      image: productImage1,
    },
    {
      id: 3,
      title: 'Pinky Promise',
      description: 'Homemade pinky promise with chocolate and milk',
      price: 15,
      image: productImage1,
    },
  ];

  const center = {
    lat: 1.5148852984714425,
    lng: 110.35493065434088,
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyByNvL1zPDKSBRXlU_0TdGNBgGCnNYSDiY"
  });

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);

    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  return (
    <>
      <Header />
      <Box component="main">
        <section className="py-20 font-bold">
          {/* <ThreeDScene zIndex={999} /> */}
          <div className="global-px">
            <div className="flex flex-col gap-6 w-full lg:w-[50%] text-sm">
              <h2 className="text-4xl font-bold">
                Blast Off to Oda Cafe:
                <br />
                <span className='text-2xl font-bold'>Your Orbital Discovery Atmosphere</span>
              </h2>
              <p className='font-normal'>
                Welcome aboard, space traveler!  Oda Cafe isn&apos;t your typical coffee shop – we&apos;re an invitation to explore a universe of flavor and connection.
              </p>
            </div>
          </div>
        </section>
        <section className="flex flex-col lg:flex-row global-px pb-20 lg:gap-32">
          <div className="flex-1 img">
            <img src={odaCafeImage} alt="" width="100%" />
          </div>
          <div className="flex-1 flex flex-col justify-center gap-5">
            <h2 className="text-quartenary font-semibold text-[35px]">
              Our name is your launchpad:
            </h2>
            <ul className="flex flex-col max-w-md space-y-1 text-gray-700 list-inside gap-4">
              {provide.map((text, idx) => (
                <li className="flex items-center gap-4" key={idx}>
                  <img src={checkCircle} alt="" />
                  <span className='font-light'>{text}</span>
                </li>
              ))}
            </ul>
          </div>
          <p className='font-normal mt-10'>
            Step inside and let the aroma of freshly roasted beans be your fuel.  Indulge in our meticulously crafted coffee creations, or explore our menu of delectable treats.  Whether you&apos;re seeking a quiet corner to work on your next mission or a social hub to connect with fellow spacefarers, Oda Cafe is your haven.
            <br />
            <br />
            Join us, and together, let&apos;s discover a universe of possibilities, one delicious cup at a time.
          </p>
        </section>
      </Box>
      <Footer />
    </>
  );
};

export default AboutUs;
