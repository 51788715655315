import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';

import axios from 'axios';
import { useSearchParams, useLocation } from 'react-router-dom';

import loadingImage from '../../assets/images/loading.svg';
import productPlaceholder from '../../assets/images/placeholder-image.webp';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Modal from '../../components/Modal';
import DataDetail from './DataDetail';
import lostImage from '../../assets/images/not_found.svg';
import {
  getTransactionDetail,
  getTransactionHistory,
} from '../../utils/dataProvider/transaction';
import useDocumentTitle from '../../utils/documentTitle';
import {
  formatDateTime,
  n_f,
} from '../../utils/helpers';

// @mui
import {
  Button,
  ButtonGroup,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';

// auth
import { useAuthContext } from '../Auth/useAuthContext';

// redux / reducer
import { useDispatch, useSelector } from 'react-redux';
import { getHistoryByUserId, getHistoryByTableId } from '../../redux/slices/order.slice';
import { isEmpty } from 'lodash';

function History() {
  const { user } = useAuthContext();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const [view, setView] = useState(0);
  const { historyByUserId, isLoading, error, historyByTableId } = useSelector((state) => state.order);
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page");
  const [detail, setDetail] = useState(null);
  const [dataDetail, setDataDetail] = useState(null);
  const notFound = (view === 0 ? historyByTableId : historyByUserId).length === 0;
  useDocumentTitle("History");

  useEffect(() => {
    dispatch(getHistoryByUserId(0, user?.UserID || 0));
    if (state?.tableId) {
      dispatch(getHistoryByTableId(state?.tableId));
    }
  }, []);

  return (
    <>
      <Header />

      <main className="bg-primary/40 py-6 md:py-12 lg:py-20 text-white">
        <section className="global-px">
          <div className="flex flex-col items-center p-3">
            <h2 className="text-3xl font-bold mb-5 text-center">
              Let&#8242;s see what you have bought!
            </h2>
            <p className='font-light'>Select items to see detail</p>

            <ToggleButtonGroup
              variant={'soft'}
              sx={{
                justifyContent: 'center',
                // change checked color
                '& .MuiToggleButton-root.Mui-selected': {
                  backgroundColor: '#8b5e3c',
                  color: 'white',
                },
                '& .MuiToggleButton-root': {
                  backgroundColor: 'white',
                  color: 'black',
                },
                mt: 4,
              }}
              exclusive
              value={view}
              onChange={(event, newValue) => {
                setView(newValue);
              }}
            >
              <ToggleButton
                value={0}
                className='capitalize'
              >
                Current Order
              </ToggleButton>
              <ToggleButton
                value={1}
                className='capitalize'
              >
                Past Order
              </ToggleButton>
            </ToggleButtonGroup>
          </div>

          {isLoading ? (
            <section className="flex justify-center items-center py-7">
              <img src={loadingImage} className="invert" />
            </section>
          ) : notFound ? (
            <div className="flex flex-col py-5" style={{ alignItems: 'center' }}>
              <img src={lostImage} width={200} />
              <p className="text-black font-semibold text-xl mt-5">No orders</p>
              <p className="text-black font-medium text-sm mt-1">
                Your history is empty, let&apos;s order some drinks
              </p>
            </div>
          ) : (
            <section className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4 text-black py-7">
              {(view === 0 ? historyByTableId : historyByUserId).map((item, key) => {
                const detail = item.OrderProductDetail ? JSON.parse(item.OrderProductDetail) : [];

                return (
                  <div
                    className="history-card flex flex-row px-4 py-5 bg-white hover:bg-gray-200 cursor-pointer duration-200 rounded-2xl gap-5 relative group"
                    onClick={() => {
                      setDetail(item)
                      setDataDetail(detail)
                    }}
                    key={key}
                  >
                    <div className="">
                      <img
                        src={
                          item.OrderProductDetail
                            ? detail[0].ProductImage
                            : productPlaceholder
                        }
                        alt=""
                        width="100px"
                        className="rounded-full  aspect-square object-cover"
                      />
                    </div>
                    <div className="flex-1 flex flex-col justify-center w-auto">
                      <div className="font-bold text-lg relative w-full">
                        {detail[0].ProductName}
                        {detail.length > 1 && (
                          <p className="absolute text-sm font-medium top-1 right-0 bg-white duration-200 group-hover:bg-gray-200">
                            + {detail.length - 1} more
                          </p>
                        )}
                      </div>
                      <p className="font-light">
                        RM {n_f(item.OrderTotalAmount)}
                      </p>
                      <p className={`font-light ${item.PaymentStatus === 'UnPaid' ? 'text-error' : ""}`}>{item.PaymentStatus}</p>
                    </div>
                  </div>
                )
              })}
            </section>
          )}
        </section>
      </main>

      <Modal
        isOpen={detail !== null}
        onClose={() => {
          setDetail(null)
          setDataDetail(null)
        }}
        className={"max-w-md md:max-w-none"}
      >
        <section className="flex flex-col-reverse md:flex-row gap-5 md:w-[80vw] duration-200">
          <DataDetail data={dataDetail} />

          <aside className="flex-1 flex flex-col gap-1 text-sm">
            <p className="font-bold mb-2 text-lg">Detail Information</p>
            <div className="flex justify-between">
              <p className="font-semibold">Grand Total</p>
              <p className='font-light'>RM {n_f(detail?.OrderTotalAmount)}</p>
            </div>
            <div className="flex justify-between">
              <p className="font-semibold">Payment Method</p>
              <p className='font-light'>{detail?.PaymentMethod}</p>
            </div>
            <div className="flex justify-between">
              <p className="font-semibold">Status</p>
              <p className='font-light'>{detail?.PaymentStatus}</p>
            </div>
            <div className="flex justify-between">
              <p className="font-semibold">Transaction at</p>
              <p className='font-light'>{detail?.CreatedDate}</p>
            </div>
            <div className="flex flex-col mt-1">
              <p className="font-semibold">Remarks</p>
              <p className="break-words font-light">{detail?.OrderRemark || "No remarks"}</p>
            </div>
          </aside>
        </section>
      </Modal>

      <Footer />
    </>
  );
}

export default History;