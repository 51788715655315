import React, { useEffect, useState } from "react";

import { toast } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";

// import { register } from "../../utils/dataProvider/auth";
import { useDispatch, useSelector } from "react-redux";
import { clearRegisterOTP, registerWithOTP } from "../../redux/slices/userInfo.slice";
import useDocumentTitle from "../../utils/documentTitle";
import { useAuthContext } from "./useAuthContext";

// google
import { GoogleLogin } from '@react-oauth/google';

// @mui
import { Divider, Button } from "@mui/material";
import Iconify from "../../components/iconify";
import { passRegex, phoneRegex } from "../../utils/helpers";

const Register = () => {
  useDocumentTitle("Register");

  const dispatch = useDispatch();
  const { isLoading, error, registerOTP } = useSelector((state) => state.userInfo);
  const { loginGoogleFB } = useAuthContext();
  const navigate = useNavigate();
  const [form, setForm] = useState({
    hpNumber: "",
    password: "",
    confirmPassword: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [errorMsg, setErrorMsg] = useState({
    hpNumber: "",
    password: "",
    confirmPassword: "",
  });

  useEffect(() => {
    if (registerOTP) {
      navigate("/auth/otp", { state: { hpNumber: form.hpNumber, password: form.password, userId: registerOTP.UserID, type: 'register' } });
    }

    return () => {
      dispatch(clearRegisterOTP());
    }
  }, [registerOTP]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  const handleSuccess = async (codeResponse) => {
    const jwtDecode = await jwt_decode(codeResponse.credential);
    onGoogleLogin(jwtDecode);
  }

  const onGoogleLogin = async (data) => {
    try {
      const resp = await loginGoogleFB(data.email, data.id, 2, data.given_name, data.family_name)
      if (resp) {
        navigate("/")
        toast.success("Welcome to ODA!\nYou can order for now!", {
          icon: "👋",
        });
      } else {
        toast.error("An error occurred while logging in");
      }
    }
    catch (error) {
      console.error(error);
      toast.error("An error occurred while logging in");
    }
  };

  function registerHandler(e) {
    e.preventDefault(); // preventing default submit
    toast.dismiss(); // dismiss all toast notification

    const valid = { hpNumber: "", password: "", confirmPassword: "" };

    // hpNumber validation
    if (!form.hpNumber) valid.hpNumber = "Input your phone number";
    else if (!form.hpNumber.match(phoneRegex))
      valid.hpNumber = "Invalid phone number";

    // password validation
    if (!form.password) valid.password = "Input your password";
    else if (form.password.length < 8)
      valid.password = "Password length minimum is 8";
    else if (!form.password.match(passRegex))
      valid.password = "Password must be combination alphanumeric";

    // confirm password validation
    if (!form.confirmPassword) valid.confirmPassword = "Input your password";
    else if (form.confirmPassword !== form.password)
      valid.confirmPassword = "Password not match";

    setErrorMsg({
      hpNumber: valid.hpNumber,
      password: valid.password,
      confirmPassword: valid.confirmPassword,
    });

    if (valid.hpNumber == "" && valid.password == "" && valid.confirmPassword == "") {
      dispatch(registerWithOTP(form.hpNumber, form.password));
    }
  }

  function onChangeForm(e) {
    return setForm((form) => {
      return {
        ...form,
        [e.target.name]: e.target.value,
      };
    });
  }

  return (
    <>
      <header className="flex float-right mb-10">
        <div className="text-xl font-semibold text-primary">Sign Up</div>
      </header>
      <section className="mt-16">
        <form className="space-y-4 md:space-y-4 relative">
          <div>
            <label
              name="hpNumber"
              htmlFor="hpNumber"
              className="text-[#4F5665] font-bold"
            >
              Phone number :
            </label>
            <input
              type="text"
              name="hpNumber"
              id="hpNumber"
              className={
                `border-gray-400 border-2 rounded-2xl p-3 w-full mt-2` +
                (errorMsg.hpNumber != "" ? " border-red-500" : "")
              }
              placeholder="Enter your phone number"
              value={form.hpNumber}
              onChange={onChangeForm}
            />
            <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1 h-4">
              {errorMsg.hpNumber != "" ? errorMsg.hpNumber : ""}
            </span>
          </div>
          <div>
            <label
              name="password"
              htmlFor="password"
              className="text-[#4F5665] font-bold"
            >
              Password :
            </label>
            <div style={{ position: 'relative' }}>
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                id="password"
                className={
                  `border-gray-400 border-2 rounded-2xl p-3 w-full mt-2` +
                  (errorMsg.password != "" ? " border-red-500" : "")
                }
                placeholder="Enter your password"
                value={form.password}
                onChange={onChangeForm}
              />
              <Button
                onClick={() => {
                  setShowPassword(!showPassword)
                }}
                style={{
                  position: 'absolute',
                  top: '55%',
                  right: '0px',
                  transform: 'translateY(-50%)',
                  color: 'black',
                }}
              >
                <Iconify icon={showPassword ? "eva:eye-off-fill" : "eva:eye-fill"} width={30} />
              </Button>
            </div>
            <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1 h-4">
              {errorMsg.password != "" ? errorMsg.password : ""}
            </span>
          </div>
          <div>
            <label
              name="confirmPassword"
              htmlFor="confirmPassword"
              className="text-[#4F5665] font-bold"
            >
              Confirm password :
            </label>
            <div style={{ position: 'relative' }}>
              <input
                type={showConfirmPassword ? "text" : "password"}
                name="confirmPassword"
                id="confirmPassword"
                className={
                  `border-gray-400 border-2 rounded-2xl p-3 w-full mt-2` +
                  (errorMsg.confirmPassword != "" ? " border-red-500" : "")
                }
                placeholder="Retype your password"
                value={form.confirmPassword}
                onChange={onChangeForm}
              />
              <Button
                onClick={() => {
                  setShowConfirmPassword(!showConfirmPassword)
                }}
                style={{
                  position: 'absolute',
                  top: '55%',
                  right: '0px',
                  transform: 'translateY(-50%)',
                  color: 'black',
                }}
              >
                <Iconify icon={showConfirmPassword ? "eva:eye-off-fill" : "eva:eye-fill"} width={30} />
              </Button>
            </div>
            <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1 h-4">
              {errorMsg.confirmPassword != "" ? errorMsg.confirmPassword : ""}
            </span>
          </div>
          <button
            type="submit"
            className={
              (isLoading
                ? "cursor-not-allowed bg-secondary text-gray-400"
                : "cursor-pointer bg-primary text-tertiary hover:bg-quartenary") +
              " w-full focus:ring-4 focus:outline-none focus:ring-primary-300 font-bold rounded-2xl text-lg p-3 text-center shadow-xl inline-flex items-center justify-center transition ease-in-out duration-150 "
            }
            onClick={registerHandler}
          >
            {isLoading ? (
              <svg
                className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            ) : (
              ""
            )}
            Sign up
          </button>
          <div className="flex justify-center">
            <GoogleLogin
              shape="pill"
              size="large"
              text="signup_with"
              onSuccess={credentialResponse => {
                handleSuccess(credentialResponse);
              }}
              onError={() => {
                toast.error('Login Failed');
              }}
            />
          </div>
          <Divider>
            Already have a account?
          </Divider>
          <Link to="/auth/login">
            <button className="w-full mt-3 text-primary focus:ring-4 focus:outline-none focus:ring-primary-300 font-bold rounded-2xl text-lg p-3 text-center lg:mb-20 bg-tertiary shadow-xl">
              Sign in here
            </button>
          </Link>
        </form>
      </section>
    </>
  );
};

export default Register;
