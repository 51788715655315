import React from 'react';
import { Card, CardContent, Chip, Typography, Box, Stack } from '@mui/material';
import memberCardBg from '../../assets/images/member-card-bg.png';
import { useAuthContext } from '../Auth/useAuthContext';
import Barcode from 'react-barcode';

const MemberCard = () => {
  const { user } = useAuthContext();

  return (
    <Card
      sx={{
        position: 'relative',
        minWidth: 310,
        minHeight: 200,
        backgroundImage: `url(${memberCardBg})`,
        backgroundSize: 'cover',
      }}
    >
      <CardContent>
        {user?.UserMembership ? (
          <>
            <Stack direction="row" alignItems="flex-start" spacing={1} mb={2}>
              <Typography variant="h6">
                {user?.UserFullName}
              </Typography>

              <Chip size='small' label={user?.MembershipType} color="primary" />
            </Stack>

            <Barcode
              value={user?.UserMembership}
              displayValue={false}
              height={30}
              background='transparent'
              textAlign='center'
              width={1}
              margin={0}
            />
            <Typography variant='body1' color="textSecondary">
              {user?.UserMembership}
            </Typography>
            <Box
              sx={{
                position: 'absolute',
                bottom: 10,
                left: 20,
              }}
              className="text-white"
            >
              <Typography variant="body2" gutterBottom>
                Valid thru
              </Typography>
              <Typography variant="body2">
                {user?.RenewDuration}
              </Typography>
            </Box>
          </>
        ) : (
          <Typography variant="h6" gutterBottom>
            Wanna be an ODA Fan?
            <br />
            <Typography variant="body2" color="textSecondary" maxWidth={150}>
              Please contact our staff for more information
            </Typography>
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}

export default MemberCard;
