import { createSlice } from '@reduxjs/toolkit';
// utils
import uploader from '../../utils/imgUploader';
import { ServerConfiguration } from '../../utils/serverConfig';
// global
import { applyDiscount } from '../global';

// ----------------------------------------------------------------------

const url = ServerConfiguration.liveServerUrl

const projectId = 1

const shopId = 1;

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  productisLoading: false,
  error: null,
  categories: [],
  catWithProducts: [],
  promoListByID: [],
  promoStatus: null,
  addPromo: null,
  updatePromo: null,
  deletePromo: null,
  selectedCategory: 0,
  product: [],
  cart: [],
  discountArray: [],
  topProducts: [],
  products: [],
};

const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    // Start Loading
    startLoading(state) {
      state.isLoading = true;
    },

    // Start Loading
    productStartLoading(state) {
      state.productisLoading = true;
    },

    // has Error
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // Get Categories
    getGategoriesSuccess(state, action) {
      state.isLoading = false;
      state.categories = action.payload;
    },

    // Get Category with Products
    getCategoryWithProductsSuccess(state, action) {
      state.isLoading = false;
      state.catWithProducts = action.payload;
    },

    // Get Promotion Listing by Hotel ID
    getPromotionListingByIDSuccess(state, action) {
      state.isLoading = false;
      state.promoListByID = action.payload;
    },

    updatePromotionStatusSuccess(state, action) {
      state.isLoading = false;
      state.promoStatus = action.payload;
    },

    // Add Promotion
    addPromotionSuccess(state, action) {
      state.isLoading = false;
      state.addPromo = action.payload;
    },

    // Update Promotion
    updatePromotionSuccess(state, action) {
      state.isLoading = false;
      state.updatePromo = action.payload;
    },

    // Delete Promotion
    deletePromotionSuccess(state, action) {
      state.isLoading = false;
      state.deletePromo = action.payload;
    },

    clearPromotionActionSuccess(state, action) {
      state.isLoading = false;
      state.promoStatus = null;
      state.deletePromo = null;
      state.addPromo = null;
      state.updatePromo = null;
      state.error = null;
    },

    // Select Category
    selectCategory(state, action) {
      state.selectedCategory = action.payload;
    },

    // Get Product
    getProductSuccess(state, action) {
      state.productisLoading = false;
      state.product = action.payload;
    },

    // Add Item to Cart
    addItemToCart(state, action) {
      // check if the productId and the variation after parse is already in cart
      const isItemExist = state.cart.some(
        (item) =>
          item.productId === action.payload.productId &&
          JSON.stringify(item.variation) === JSON.stringify(action.payload.variation)
      );
      if (!isItemExist) {
        state.cart = [...state.cart, action.payload];
      } else {
        state.cart = state.cart.map((item) => {
          if (
            item.productId === action.payload.productId &&
            JSON.stringify(item.variation) === JSON.stringify(action.payload.variation)
          ) {
            return {
              ...item,
              qty: item.qty + action.payload.qty,
            };
          }
          return item;
        }
        );
      }

      applyDiscount(state, action.payload.user);
    },

    // Delete Item from Cart
    deleteItemFromCart(state, action) {
      state.cart = state.cart.filter((item) => item.id !== action.payload.id);

      // change id based on the new cart
      state.cart = state.cart.map((item, index) => ({ ...item, id: index + 1 }));

      applyDiscount(state, action.payload.user);
    },

    // Increment Qty
    incrementQty(state, action) {
      const index = state.cart.findIndex((item) => item.id === action.payload.id);
      state.cart[index].qty += 1;

      applyDiscount(state, action.payload.user);
    },

    // Decrement Qty
    decrementQty(state, action) {
      const index = state.cart.findIndex((item) => item.id === action.payload.id);
      state.cart[index].qty -= 1;

      // If the item is in the discountArray, remove one quantity of it
      const discountIndex = state.discountArray.findIndex((item) => item.id === action.payload);
      if (discountIndex !== -1) {
        state.discountArray.splice(discountIndex, 1);
      }

      applyDiscount(state, action.payload.user);
    },

    // Get Top Products Success
    getTopProductsSuccess(state, action) {
      state.isLoading = false;
      state.topProducts = action.payload;
    },

    // Clear Cart
    clearCart(state) {
      state.cart = [];
    },

    // Get Products
    getProductsSuccess(state, action) {
      state.isLoading = false;
      state.products = action.payload;
    },

    // Clear Product
    clearProduct(state) {
      state.product = [];
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  clearPromotionActionSuccess,
  selectCategory,
  addItemToCart,
  deleteItemFromCart,
  incrementQty,
  decrementQty,
  clearCart,
  clearProduct,
} = slice.actions;

// ----------------------------------------------------------------------

export function getCategories() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Product_CategoryListByAll?PROJECTID=${projectId}&SHOPID=${shopId}`
      )
      const json = await response.json();
      if (json.Message) {
        dispatch(slice.actions.hasError(json.Message));
      } else {
        const data = JSON.parse(json)[0];
        if (data.ReturnVal === 1) {
          dispatch(slice.actions.getGategoriesSuccess(JSON.parse(data.ReturnData)));
        } else {
          dispatch(slice.actions.hasError(data.ReturnMsg));
        }
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCatWithProducts() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Product_CategoryListWithProductListingByTagAndKeyword?PROJECTID=${projectId}&SHOPID=${shopId}`
      )
      const json = await response.json();
      if (json.Message) {
        dispatch(slice.actions.hasError(json.Message));
      } else {
        const data = JSON.parse(json)[0];
        if (data.ReturnVal === 1) {
          dispatch(slice.actions.getCategoryWithProductsSuccess(JSON.parse(data.ReturnData)));
        } else {
          dispatch(slice.actions.hasError(data.ReturnMsg));
        }
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProductBySlug(slug) {
  return async (dispatch) => {
    dispatch(slice.actions.productStartLoading());
    try {
      const response = await fetch(
        `${url}Product_ItemListBySlugByPlatform?ProductSlug=${slug}&Platform=WEPAPP`
      )
      const json = await response.json();
      if (json.Message) {
        dispatch(slice.actions.hasError(json.Message));
      } else {
        const data = JSON.parse(json)[0];
        if (data.ReturnVal === 1) {
          dispatch(slice.actions.getProductSuccess(JSON.parse(data.ReturnData)));
        } else {
          dispatch(slice.actions.hasError(data.ReturnSqlError));
        }
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updatePromotionStatus(promotionId, isEnable, userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Promo_UpdatePromotionActiveInd?PROMOTIONID=${promotionId}&ACTIVEIND=${isEnable}&USERID=${userId}`
      )
      const json = await response.json();
      if (json.Message) {
        dispatch(slice.actions.hasError(json.Message));
      } else {
        const data = JSON.parse(json)[0];
        if (data.ReturnVal === 1) {
          dispatch(slice.actions.updatePromotionStatusSuccess(JSON.parse(data.ReturnData)[0]));
        } else {
          dispatch(slice.actions.hasError(data.ReturnSqlError));
        }
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addPromotion(userId, name, description, startDate, endDate, categoryId, applyStartDate, applyEndDate, bannerImageName, slideOrder, hotelId, roomId, promotionId, discountRate, rateType, noOfFreeNight, discountType, minRoom, minNight, nightSpec, imageFile) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await uploader([imageFile], [bannerImageName], 'accommodationPromotion', hotelId)

      if (!resp) {
        dispatch(slice.actions.hasError('Failed to upload image'));
        return;
      }

      const response = await fetch(
        `${url}Promo_AddHotelPromotion?USERID=${userId}&PROMOTIONTITLE=${name}&ProjectID=${projectId}&PROMOTIONDESC=${description}&PROMOTIONSTARTDATE=${startDate}&PROMOTIONENDDATE=${endDate}&ACCESSCATEGORYID=${categoryId}&APPLYSTARTDATE=${applyStartDate}&APPLYENDDATE=${applyEndDate}&BANNERIMAGE=${bannerImageName}&SLIDEORDER=${slideOrder}&HOTELID=${hotelId}&ROOMID=${roomId}&TYPEOFPROMOTION=${promotionId}&DISCOUNTRATE=${discountRate}&DISCOUNTRATETYPE=${rateType}&FREENIGHTNUM=${noOfFreeNight}&DISCOUNTTYPE=${discountType}&MINROOM=${minRoom}&MINNIGHT=${minNight}&NIGHTSPECIFICATION=${nightSpec}`
      )
      const json = await response.json();
      if (json.Message) {
        dispatch(slice.actions.hasError(json.Message));
      } else {
        const data = JSON.parse(json)[0];
        if (data.ReturnVal === 1) {
          dispatch(slice.actions.addPromotionSuccess(JSON.parse(data.ReturnData)[0]));
        } else {
          dispatch(slice.actions.hasError(data.ReturnSqlError));
        }
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updatePromotion(promotionId, userId, name, description, bannerImageName, slideOrder, promoStartDate, promoEndDate, applyStartDate, applyEndDate, promotionItemId, hotelId, roomId, promotionType, discountRate, rateType, noOfFreeNight, discountType, minRoom, minNight, nightSpec, activeInd, imageFile) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      if (typeof imageFile === 'object') {
        const resp = await uploader([imageFile], [bannerImageName], 'accommodationPromotion', hotelId)

        if (!resp) {
          dispatch(slice.actions.hasError('Failed to upload image'));
          return;
        }
      }

      const response = await fetch(
        `${url}Promo_UpdateHotelPromotion?PROMOTIONID=${promotionId}&USERID=${userId}&PROMOTIONTITLE=${name}&PROMOTIONDESC=${description}&BANNERIMAGE=${bannerImageName}&SLIDEORDER=${slideOrder}&PROMOTIONSTARTDATE=${promoStartDate}&PROMOTIONENDDATE=${promoEndDate}&APPLYSTARTDATE=${applyStartDate}&APPLYENDDATE=${applyEndDate}&PROMOTIONITEMID=${promotionItemId}&HOTELID=${hotelId}&ROOMID=${roomId}&TYPEOFPROMOTION=${promotionType}&DISCOUNTRATE=${discountRate}&DISCOUNTRATETYPE=${rateType}&FREENIGHTNUM=${noOfFreeNight}&DISCOUNTTYPE=${discountType}&MINROOM=${minRoom}&MINNIGHT=${minNight}&NIGHTSPECIFICATION=${nightSpec}&ACTIVEIND=${activeInd}`
      )
      const json = await response.json();
      if (json.Message) {
        dispatch(slice.actions.hasError(json.Message));
      } else {
        const data = JSON.parse(json)[0];
        if (data.ReturnVal === 1) {
          dispatch(slice.actions.updatePromotionSuccess(JSON.parse(data.ReturnData)[0]));
        } else {
          dispatch(slice.actions.hasError(data.ReturnSqlError));
        }
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deletePromotion(promotionId, userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Promo_DeletePromotion?PROMOTIONID=${promotionId}&USERID=${userId}`
      )
      const json = await response.json();
      if (json.Message) {
        dispatch(slice.actions.hasError(json.Message));
      } else {
        const data = JSON.parse(json)[0];
        if (data.ReturnVal === 1) {
          dispatch(slice.actions.deletePromotionSuccess(JSON.parse(data.ReturnData)[0]));
        } else {
          dispatch(slice.actions.hasError(data.ReturnSqlError));
        }
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearPromotionAction() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.clearPromotionActionSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getTopProducts(noOfProducts) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Product_ItemListByType?TYPE=TOPLIST&TYPEVALUE=${noOfProducts}&USERID=0&PROJECTID=${projectId}&SHOPID=${shopId}&PRODUCTPERPAGE=999&PAGE=1`
      )
      const json = await response.json();
      if (json.Message) {
        dispatch(slice.actions.hasError(json.Message));
      } else {
        const data = JSON.parse(json)[0];
        if (data.ReturnVal === 1) {
          dispatch(slice.actions.getTopProductsSuccess(JSON.parse(data.ReturnData)));
        } else {
          dispatch(slice.actions.hasError(data.ReturnSqlError));
        }
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProducts() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Product_CategoryListWithProductListingByCategory?PROJECTID=${projectId}&SHOPID=${shopId}`
      )
      const json = await response.json();
      if (json.Message) {
        dispatch(slice.actions.hasError(json.Message));
      } else {
        const data = JSON.parse(json)[0];
        if (data.ReturnVal === 1) {
          dispatch(slice.actions.getProductsSuccess(JSON.parse(data.ReturnData)));
        } else {
          dispatch(slice.actions.hasError(data.ReturnSqlError));
        }
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}