import React from "react";
import { toast } from "react-hot-toast";
import { NavLink, useNavigate } from "react-router-dom";

import placeholderProfile from "../assets/images/placeholder-profile.jpg";
import { useAuthContext } from "../pages/Auth/useAuthContext";
// google
import { googleLogout } from '@react-oauth/google';

// navs
import navs from "../navigation/navs.js";
import navsWithoutHomeAbout from "../navigation/navsWithoutHomeAbout.js";
import { isMobile } from "../utils/helpers.js";

function Sidebar({ onClose }) {
  const navigate = useNavigate();
  const { user, logout } = useAuthContext();
  const selectedNavs = !isMobile ? navs : navsWithoutHomeAbout;

  const adminNavs = [
    {
      name: "Admin Dashboard",
      to: "/admin"
    },
    {
      name: "Manage Order",
      to: "/manage-order"
    },
  ]

  const handleSignout = async () => {
    const resp = await logout(user?.UserID);
    googleLogout();
    if (resp) {
      navigate("/");
    } else {
      toast.error("Failed to sign out");
    }
  }

  return (
    <>
      <div className="h-full">
        <div className="p-5 flex flex-col h-full">
          <div
            className={`flex flex-row gap-x-2 items-center mb-8 relative ${user && "cursor-pointer"
              }`}
            onClick={() => user && navigate("/profile")}
          >
            <div className="avatar">
              <div className="w-12 rounded-xl border">
                <img
                  src={
                    user?.UserProfileImage
                      ? user?.UserProfileImage
                      : placeholderProfile
                  }
                />
              </div>
            </div>
            <div className="flex-col">
              {user ? (
                <>
                  <p className="font-medium">{user?.UserFullName}</p>
                  <p className="text-sm">{user?.MembershipType}</p>
                </>
              ) : (
                <>
                  <p className="font-medium">Guest</p>
                  <p className="text-sm ">
                  </p>
                </>
              )}
            </div>
            <button
              className="navbar-close absolute right-0 top-0"
              onClick={(e) => {
                e.stopPropagation();
                onClose();
              }}
            >
              <svg
                className="h-6 w-6 text-gray-400 cursor-pointer hover:text-gray-500"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>
          </div>
          <div>
            <ul>
              {selectedNavs.map((nav, idx) => (
                <li className="mb-1" key={idx}>
                  <NavLink
                    to={nav.path}
                    className={({ isActive }) =>
                      (isActive ? "font-bold text-primary" : "font-semibold") + " block p-4 text-sm text-gray-400 hover:bg-primary/40 hover:text-quartenary rounded"
                    }
                  >
                    {nav.name}
                  </NavLink>
                </li>
              ))}
              {Number(user?.UserTypeID) === 1 || Number(user?.UserTypeID) === 2 && (
                <>
                  {adminNavs.map((nav, idx) => (
                    <li className="mb-1" key={idx}>
                      <NavLink
                        className="block p-4 text-sm font-semibold text-gray-400 hover:bg-primary/40 hover:text-quartenary rounded"
                        to={nav.path}
                      >
                        {nav.name}
                      </NavLink>
                    </li>
                  ))}
                </>
              )}
            </ul>
          </div>
          <div className="mt-auto pt-6">
            {user ? (
              <button
                onClick={() => handleSignout()}
                className="block p-4 text-sm font-semibold text-quartenary bg-primary/40 rounded w-full rounded-xl"
                to="/"
              >
                Sign out
              </button>
            ) : (
              <>
                <NavLink
                  className="block px-4 py-3 mb-3 leading-loose text-xs text-center font-semibold bg-gray-50 hover:bg-gray-100 rounded-xl shadow-md"
                  to="/auth/login"
                >
                  Sign In
                </NavLink>
                <NavLink
                  className="block px-4 py-3 mb-2 leading-loose text-xs text-primary text-center font-semibold border-2 border-primary hover:bg-primary hover:text-tertiary rounded-xl shadow-md"
                  to="/auth/register"
                >
                  Sign Up
                </NavLink>
              </>
            )}
            <p className="my-4 text-xs text-center text-gray-400">
              <span>Copyright © 2023</span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
