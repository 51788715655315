import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// @mui
import { Button, ButtonGroup } from '@mui/material';
import Iconify from '../../../components/iconify';

CartItem.PropTypes = {
  item: PropTypes.object.isRequired,
  idx: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  setRemove: PropTypes.func.isRequired,
  handlePlus: PropTypes.func.isRequired,
  handleMinus: PropTypes.func.isRequired,
};

export default function CartItem({
  item,
  idx,
  setRemove,
  handlePlus,
  handleMinus,
}) {
  const { id, productName, img, qty, variation, basePrice, discountPrice } = item;

  const variations = variation.flat();

  const isValidation = !isEmpty(variations) && typeof variations[0] !== 'undefined';

  const price = isValidation ? variations.reduce((acc, v) => acc + JSON.parse(v).ProductVariationPrice, basePrice) : basePrice;

  return (
    <div
      className="flex flex-row gap-2 lg:gap-5 w-full lg:text-lg items-center relative"
      key={idx}
    >
      <aside className="flex-1">
        <img
          src={img}
          alt={productName}
          className="aspect-square h-auto object-cover rounded-xl"
        />
      </aside>
      <aside className="flex-[2_2_0%] flex flex-col gap-2">
        <p className="font-semibold">{productName}</p>
        <ButtonGroup size="small">
          <Button
            onClick={() => handleMinus(item)}
          >
            -
          </Button>
          <Button disabled>{qty}</Button>
          <Button
            onClick={() => handlePlus(item)}
          >
            +
          </Button>
        </ButtonGroup>
        {isValidation ? (
          <>
            {variations.map((v, i) => (
              <p key={i} className='font-light text-sm'>
                {`${JSON.parse(v).ProductVariation} - ${JSON.parse(v).ProductVariationValue}`}
              </p>
            ))}
          </>
        ) : (
          <></>
        )}
      </aside>
      <aside className="flex-1">
        <p className={`text-right`}>
          RM {(price * qty).toFixed(2)}
        </p>

        <button
          onClick={() => setRemove(item)}
          style={{
            float: 'right',
          }}
        >
          <Iconify icon="mdi:trash-can-circle" color="red" width={30} />
        </button>
      </aside>
    </div>
  )
}