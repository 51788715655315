import React, { useEffect, useState } from "react";

import toast from "react-hot-toast";

import { isEqual } from "lodash";

import { useAuthContext } from "../Auth/useAuthContext";

// @mui
import { Box, Dialog } from "@mui/material";

import iconPen from "../../assets/icons/icon-pen.svg";
import loadingImage from "../../assets/images/loading.svg";
import placeholderImage from "../../assets/images/placeholder-profile.jpg";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Iconify from "../../components/iconify";
import useDocumentTitle from "../../utils/documentTitle";
import EditPassword from "./EditPassword";

// utils
import { fDate } from "../../utils/formatTime";
import { emailRegex, phoneRegex } from "../../utils/helpers";
import MemberCard from './MemberCard';

// redux / reducers
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../../redux/slices/userInfo.slice";

function Profile() {
  const { user, updateUserContext } = useAuthContext();
  const [data, setData] = useState({});
  const [form, setForm] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editPassModal, setEditPassModal] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const { updatedProfile, error } = useSelector((state) => state.userInfo);
  const [errorMsg, setErrorMsg] = useState({
    email: "",
    hpNumber: "",
  });

  const gender = [
    {
      label: "Male",
      value: "1",
    },
    {
      label: "Female",
      value: "2",
    },
  ]

  const dispatch = useDispatch();

  useEffect(() => {
    if (updatedProfile) {
      updateUserContext(updatedProfile);
      toast.success("Profile updated successfully");
    }
  }, [updatedProfile]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  const closeEpassModal = () => {
    setEditPassModal(false);
  };
  const switchEpassModal = () => {
    setEditPassModal(!editPassModal);
  };

  const openCardDialog = () => {
    setOpenDialog(true);
  };

  const closeCardDialog = () => {
    setOpenDialog(false);
  };

  useDocumentTitle("Profile");

  const handleNavigateForm = (e) => {
    e.preventDefault();
    setEditMode(!editMode);
    // navigate to external link with blank target
    window.open('https://forms.gle/n7xQjNRaaFLA1HuM9', "_blank");
  };

  useEffect(() => {
    if (user) {
      setIsLoading(true);
      const updatedObject = { ...user };
      for (const [key, value] of Object.entries(updatedObject)) {
        if (value === null || value === "null") {
          updatedObject[key] = "";
        }
      }
      setData(updatedObject);
      setForm(updatedObject);
      setIsLoading(false);
    }
  }, [user]);

  const formHandler = (e) => {
    const valid = {
      email: "",
      hpNumber: "",
    }

    if (editMode) {
      return setForm((form) => {
        if (e.target.name === "UserEmailAddress") {
          if (!e.target.value.match(emailRegex)) {
            valid.email = "Invalid email";
          }
          setErrorMsg(valid);
        }

        if (e.target.name === "UserContactNo") {
          if (!e.target.value.match(phoneRegex)) {
            valid.hpNumber = "Invalid phone number";
          }
          setErrorMsg(valid);
        }

        return {
          ...form,
          [e.target.name]: e.target.value,
        };
      });
    }
  };

  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    setSelectedFile(e.target.files[0]);
  };

  const handleClearClick = () => {
    setSelectedFile(null);
  };

  const saveHandler = () => {
    let changes = {};
    let newFilename = user?.UserProfileImage ? (user?.UserProfileImage).split('/').pop() : "-";

    for (let key in form) {
      if (form[key] !== data[key]) {
        changes[key] = form[key];
      }
    }

    if (selectedFile) {
      form.image = selectedFile;
      const filename = new Date().valueOf();
      const extension = selectedFile.name.split('.').pop();
      newFilename = `${filename}.${extension}`;
    }

    toast.promise(
      dispatch(updateProfile(user?.UserID, form.FirstName, form.LastName, form.UserEmailAddress, form.UserGender, form.UserContactNo, form.UserDOB, form.image, newFilename)),
      {
        loading: "Saving changes...",
        error: "Something went wrong",
      }
    );
  };

  return (
    <>
      <Header />
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <EditPassword isOpen={editPassModal} onClose={closeEpassModal} />

          <CardDialog open={openDialog} onClose={closeCardDialog} />

          <main className="bg-profile">
            <div className="global-px py-10 space-y-3">
              <section className="text-white text-2xl font-bold">
                User Profile
              </section>
              <section className="flex flex-col lg:flex-row bg-white rounded-2xl">
                <section className="relative flex-1 flex flex-col items-center p-10">
                  <div className="w-44" style={{ position: 'relative' }}>
                    <Iconify
                      icon="carbon:close-filled"
                      width={30}
                      color="#ff7070"
                      onClick={handleClearClick}
                      className="cursor-pointer z-99 absolute"
                      style={{
                        right: "0",
                        transform: "translate(-50%,0%)",
                      }}
                    />
                    <img
                      src={
                        selectedFile
                          ? preview
                          : data.UserProfileImage
                            ? data.UserProfileImage
                            : placeholderImage
                      }
                      alt=""
                      className="w-44 aspect-square object-cover rounded-full mb-3"
                    />

                    <input
                      className="hidden"
                      type="file"
                      onChange={onSelectFile}
                      accept="image/png, image/jpeg, image/webp"
                      id="imageUp"
                    />
                    <label htmlFor="imageUp">
                      <p
                        className="bg-primary text-white p-2 rounded-full absolute bottom-1 right-1"
                      >
                        <Iconify icon="solar:gallery-circle-bold" width={30} />
                      </p>
                    </label>
                  </div>
                  <p className="font-bold text-lg">{data.UserFullName || ""}</p>
                  <p className="mb-5 mt-2 font-semibold relative flex items-center">
                    <span className="text-primary font-semibold text-lg">{user?.WalletAmount || 0}</span>&nbsp;points
                    <button className="rounded-full bg-primary h-8 w-8 flex items-center justify-center ml-2" onClick={openCardDialog}>
                      <Iconify icon="lucide:wallet-cards" width={20} className="text-tertiary" />
                    </button>
                  </p>
                  <button
                    className="bg-primary border-2 secondary py-4 w-[75%] rounded-2xl mb-4 text-tertiary font-bold shadow-lg"
                    onClick={switchEpassModal}
                  >
                    Edit Password
                  </button>
                  <button
                    className="bg-white border-2 secondary py-4 w-[75%] rounded-2xl mb-8 text-primary font-bold shadow-lg disabled:text-gray-300 disabled:bg-gray-100"
                    onClick={handleNavigateForm}
                  >
                    <div className="flex items-center justify-center">
                      <Iconify icon="bx:bxs-file-blank" width={20} className="text-primary" />
                      &nbsp;
                      Survey Form
                    </div>
                  </button>
                </section>
                <section className="flex-[2_2_0%] p-4 md:p-10 lg:pl-0">
                  <form className="bg-white drop-shadow-2xl rounded-xl border-b-[6px] border-solid border-[#6a4029] px-5 py-3 relative">
                    <button
                      className={`${editMode ? "bg-quartenary" : "bg-primary"
                        } absolute top-3 p-2 rounded-full right-3 cursor-pointer select-none`}
                      onClick={(e) => {
                        e.preventDefault();
                        setForm(data);
                        return setEditMode(!editMode);
                      }}
                    >
                      <img src={iconPen} alt="" />
                    </button>
                    <p className="text-primary text-xl font-bold">Details</p>
                    <div className="grid lg:grid-cols-[55%_35%] gap-x-5 gap-y-8 py-5">
                      <div className="input-profile">
                        <label htmlFor="FirstName" className="text-[#9f9f9f] font-normal">
                          First name
                        </label>
                        <input
                          type="text"
                          value={form.FirstName || ""}
                          name="FirstName"
                          id="FirstName"
                          disabled={!editMode}
                          onChange={formHandler}
                          className="focus:outline-none border-b-[1px] border-black w-full font-normal"
                        />
                      </div>
                      <div className="input-profile hidden lg:block"></div>
                      <div className="input-profile">
                        <label htmlFor="LastName" className="text-[#9f9f9f] font-normal">
                          Last name
                        </label>
                        <input
                          type="text"
                          value={form.LastName || ""}
                          name="LastName"
                          id="LastName"
                          disabled={!editMode}
                          onChange={formHandler}
                          className="focus:outline-none border-b-[1px] border-black w-full font-normal"
                        />
                      </div><div className="flex flex-col">
                        <label htmlFor="UserEmailAddress" className="text-[#9f9f9f] font-normal">
                          Email Address
                        </label>
                        <input
                          type="text"
                          id="UserEmailAddress"
                          name="UserEmailAddress"
                          value={form.UserEmailAddress || ""}
                          className={"focus:outline-none border-b-[1px] border-black w-full font-normal" + (errorMsg.email != "" ? " border-red-500" : "")}
                          onChange={formHandler}
                          disabled={!editMode}
                        />
                        <span className={"items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1 h-4" + (errorMsg.email !== "" ? " flex" : " hidden")}>
                          {errorMsg.email != "" ? errorMsg.email : ""}
                        </span>
                      </div>
                      <div className="flex flex-col">
                        <label htmlFor="UserContactNo" className="text-[#9f9f9f] font-normal">
                          Mobile number
                        </label>
                        <input
                          type="text"
                          value={form.UserContactNo || ""}
                          id="UserContactNo"
                          name="UserContactNo"
                          onChange={formHandler}
                          className={"focus:outline-none border-b-[1px] border-black w-full font-normal" + (errorMsg.hpNumber != "" ? " border-red-500" : "")}
                          disabled={!editMode}
                        />
                        <span className={"items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1 h-4" + (errorMsg.hpNumber !== "" ? " flex" : " hidden")}>
                          {errorMsg.hpNumber != "" ? errorMsg.hpNumber : ""}
                        </span>
                      </div>
                      <div className="input-profile">
                        <label htmlFor="UserDOB" className="text-[#9f9f9f] font-normal">
                          Date of Birth
                        </label>
                        <input
                          type="date"
                          value={fDate(form.UserDOB, 'yyyy-MM-dd') || fDate(new Date(), 'yyyy-MM-dd')}
                          id="UserDOB"
                          name="UserDOB"
                          disabled={!editMode}
                          onChange={formHandler}
                          className="focus:outline-none border-b-[1px] border-black w-full font-normal"
                        />
                      </div>
                    </div>
                    <div className="flex justify-around items-center">
                      {gender.map((item, index) => (
                        <div className="flex items-center gap-2" key={index}>
                          <input
                            type="radio"
                            id={`gender${item.label}`}
                            name="UserGender"
                            value={item.value}
                            className="hidden peer"
                            checked={String(form.UserGender) === item.value}
                            disabled={!editMode}
                            onChange={formHandler}
                            onClick={() => setForm({ ...form, UserGender: item.value })}
                            required
                          />
                          <label
                            htmlFor={`gender${item.label}`}
                            className="inline-flex items-center justify-between p-2 bg-[#BABABA59] rounded-full cursor-pointer peer-checked:text-white peer-checked:bg-primary peer-checked:font-bold hover:text-gray-600 hover:bg-gray-100 w-2 h-2 border-2 border-tertiary"
                          >
                            <div className="block"></div>
                          </label>
                          <label htmlFor={`gender${item.label}`} className="font-normal">{item.label}</label>
                        </div>
                      ))}
                    </div>
                  </form>

                  <Box
                    mt={2}
                    display="flex"
                    justifyContent={{ xs: "center", md: "flex-end" }}
                  >
                    <button
                      className="bg-primary font-bold border-2 secondary p-4 rounded-2xl mb-3 text-white text-xl shadow-lg disabled:cursor-not-allowed disabled:bg-gray-400"
                      id="saveChange"
                      onClick={saveHandler}
                      disabled={(isEqual(form, data) && !selectedFile)}
                    >
                      Save Change
                    </button>
                  </Box>
                </section>
              </section>
            </div>
          </main>
        </>
      )}
      <Footer />
    </>
  );
}
export default Profile;

const CardDialog = ({ open, onClose, ...other }) => {
  return (
    <Dialog open={open} onClose={onClose} {...other}>
      <MemberCard />
    </Dialog>
  );
};

const Loading = (props) => {
  return (
    <main className="h-[80vh] flex items-center justify-center">
      <div>
        <img src={loadingImage} alt="Loading..." />
      </div>
    </main>
  );
};