const navs = [
  {
    name: "Home",
    path: "/"
  },
  {
    name: "About Us",
    path: "/aboutus"
  },
  {
    name: "Products",
    path: "/products"
  },
  {
    name: "Your Cart",
    path: "/cart"
  },
  {
    name: "History",
    path: "/history"
  },
]

export default navs;