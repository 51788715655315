const navsWithoutHomeAbout = [
  {
    name: "Products",
    path: "/products"
  },
  {
    name: "Your Cart",
    path: "/cart"
  },
  {
    name: "History",
    path: "/history"
  },
]

export default navsWithoutHomeAbout;