import React, { useState } from "react";

import toast from "react-hot-toast";
import { Link, useNavigate, useLocation } from "react-router-dom";

import jwt_decode from "jwt-decode";
import Iconify from "../../components/iconify";
import useDocumentTitle from "../../utils/documentTitle";
import { useAuthContext } from './useAuthContext';

// @mui
import {
  Box,
  Button,
  Divider
} from "@mui/material";
// google
import { GoogleLogin } from '@react-oauth/google';
// axios
import { phoneRegex } from "../../utils/helpers";

const Login = () => {
  const { login, loginGoogleFB } = useAuthContext();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  useDocumentTitle("Sign in");

  const [form, setForm] = React.useState({
    hpNumber: "",
    password: "",
    rememberMe: false,
  });
  const [error, setError] = React.useState({
    hpNumber: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  function loginHandler(e) {
    e.preventDefault(); // preventing default submit
    toast.dismiss(); // dismiss all toast
    const valid = { hpNumber: "", password: "" };

    if (!form.hpNumber) valid.hpNumber = "Input your phone number";
    if (!form.hpNumber.match(phoneRegex)) valid.hpNumber = "Invalid phone number";
    if (!form.password) valid.password = "Input your password";

    setError({
      hpNumber: valid.hpNumber,
      password: valid.password,
    });

    if (valid.hpNumber == "" && valid.password == "" && !isLoading) {
      setIsLoading(true);
      toast.promise(
        login(form.hpNumber, form.password, form.rememberMe),
        {
          loading: () => {
            e.target.disabled = true;
            return "Please wait a moment";
          },
          success: (result) => {
            if (result.success) {
              navigate(state && state.from !== "" ? "/cart" : "/products");
              toast.success("Welcome to ODA!\nYou can order for now!", {
                icon: "👋",
                duration: 2000,
              });
            } else {
              setIsLoading(false);
              e.target.disabled = false;
              toast.error("Incorrect phone number or password");
            }
          },

          error: (e) => {
            console.log("error", e)
            setIsLoading(false);
            e.target.disabled = false;
            return "An error occurred while logging in";
          },
        }
      );

    }
  }

  function onChangeForm(e) {
    return setForm((form) => {
      return {
        ...form,
        [e.target.name]: e.target.value,
      };
    });
  }

  function onCheck(e) {
    return setForm((form) => {
      return {
        ...form,
        [e.target.name]: !form[e.target.name],
      };
    });
  }

  const handleSuccess = async (codeResponse) => {
    const jwtDecode = await jwt_decode(codeResponse.credential);
    onGoogleLogin(jwtDecode);
  }

  const onGoogleLogin = async (data) => {
    try {
      const resp = await loginGoogleFB(data.email, data.id, 2, data.given_name, data.family_name)
      if (resp) {
        navigate(state && state.from !== "" ? "/cart" : "/products");
        toast.success("Welcome to ODA!\nYou can order for now!", {
          icon: "👋",
        });
      } else {
        toast.error("An error occurred while logging in");
      }
    }
    catch (error) {
      console.error(error);
      toast.error("An error occurred while logging in");
    }
  };

  return (
    <>
      {state && state.from !== "" ? (
        <header className="flex justify-between mb-10">
          <Box>
            <Button
              variant='text'
              onClick={() => navigate(-1)}
              startIcon={<Iconify icon="carbon:chevron-left" />}
            >
              Back
            </Button>
          </Box>
          <div className="text-lg md:text-xl font-semibold text-primary">
            Sign in
          </div>
        </header>
      ) : (
        <header className="flex float-right mb-10">
          <div className="text-lg md:text-xl font-semibold text-primary">
            Sign in
          </div>
        </header>
      )}
      <section className="mt-16">
        <form className="space-y-4 md:space-y-6 relative" onSubmit={loginHandler}>
          <div>
            <label
              name="hpNumber"
              htmlFor="hpNumber"
              className="text-[#4F5665] font-bold"
            >
              Phone number :
            </label>
            <input
              type="text"
              name="hpNumber"
              id="hpNumber"
              className={
                `border-gray-400 border-2 rounded-2xl p-3 w-full mt-2` +
                (error.hpNumber != "" ? " border-red-500" : "")
              }
              placeholder="Enter your phone number"
              value={form.hpNumber}
              onChange={onChangeForm}
            />
            <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1 h-4">
              {error.hpNumber != "" ? error.hpNumber : ""}
            </span>
          </div>
          <div>
            <label
              name="password"
              htmlFor="password"
              className="text-[#4F5665] font-bold"
            >
              Password :
            </label>
            <div style={{ position: 'relative' }}>
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                id="password"
                className={
                  `border-gray-400 border-2 rounded-2xl p-3 w-full mt-2` +
                  (error.password != "" ? " border-red-500" : "")
                }
                placeholder="Enter your password"
                value={form.password}
                onChange={onChangeForm}
              />
              <Button
                onClick={() => {
                  setShowPassword(!showPassword)
                }}
                style={{
                  position: 'absolute',
                  top: '55%',
                  right: '0px',
                  transform: 'translateY(-50%)',
                  color: 'black',
                }}
              >
                <Iconify icon={showPassword ? "eva:eye-off-fill" : "eva:eye-fill"} width={30} />
              </Button>
            </div>
            <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1 h-4">
              {error.password != "" ? error.password : ""}
            </span>
          </div>
          <div className="flex items-center justify-between">
            <div className="flex items-start">
              <div className="flex items-center h-5">
                <input
                  id="remember"
                  aria-describedby="rememberMe"
                  name="rememberMe"
                  type="checkbox"
                  className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                  onChange={onCheck}
                />
              </div>
              <div className="ml-3 text-sm">
                <label
                  htmlFor="remember"
                  className="text-quantenary font-normal"
                >
                  Remember me
                </label>
              </div>
            </div>
            <Link
              to="/auth/forgotpass"
              className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500"
              name="rememberMe"
            >
              Forgot password?
            </Link>
          </div>
          <button
            type="submit"
            className={
              (isLoading
                ? "cursor-not-allowed bg-secondary text-gray-400"
                : "cursor-pointer bg-primary text-tertiary hover:bg-quartenary") +
              " w-full focus:ring-4 focus:outline-none focus:ring-primary-300 font-bold rounded-2xl text-base md:text-lg p-3 text-center  shadow-xl inline-flex items-center justify-center transition ease-in-out duration-150"
            }
            onClick={loginHandler}
          >
            {isLoading ? (
              <svg
                className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            ) : (
              ""
            )}
            Sign in
          </button>
          <div className="flex justify-center">
            <GoogleLogin
              shape="pill"
              size="large"
              onSuccess={credentialResponse => {
                handleSuccess(credentialResponse);
              }}
              onError={() => {
                toast.error('Login Failed');
              }}
            />
          </div>
          <Divider>
            <span className="font-normal">Don&apos;t have an account?</span>
          </Divider>
          <Link to="/auth/register">
            <button className="w-full mt-3 text-primary focus:ring-4 focus:outline-none focus:ring-primary-300 font-bold rounded-2xl text-base md:text-lg p-3 text-center lg:mb-20 bg-tertiary shadow-xl">
              Sign up here
            </button>
          </Link>
        </form>
      </section>
    </>
  );
};

export default Login;
