import React, {
  useEffect,
  useState
} from 'react';

import { toast } from 'react-hot-toast';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../Auth/useAuthContext';

// @mui
import {
  Box,
  Button,
} from '@mui/material';
import Iconify from '../../components/iconify';

import illustrationsPromo from "../../assets/illustrations/mobile-search-undraw.png";
import Header from '../../components/Header';
import Modal from '../../components/Modal';
import { clearTableId, getPaymentMethods, submitOrder, clearError } from '../../redux/slices/order.slice';
import { clearCart, decrementQty, deleteItemFromCart, incrementQty } from '../../redux/slices/product.slice';
import useDocumentTitle from '../../utils/documentTitle';
import { CartItem } from './components';

function Cart() {
  const { user } = useAuthContext();
  const [loginModal, setLoginModal] = useState(false);
  const { cart, discountArray } = useSelector((state) => state.product);
  const { isLoading: isSubmitting, tableId, paymentMethods, orderSubmission, error } = useSelector((state) => state.order);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [form, setForm] = useState({
    payment: "",
  });

  useDocumentTitle("My Cart");

  useEffect(() => {
    if (error) {
      toast.error(error);
    }

    return () => {
      dispatch(clearError());
    }
  }, [error]);

  useEffect(() => {
    dispatch(getPaymentMethods());
  }, []);

  useEffect(() => {
    if (orderSubmission) {
      dispatch(clearTableId());
      dispatch(clearCart());
      navigate("/history", { state: { tableId: orderSubmission.TableID } });
    }
  }, [orderSubmission]);

  function onChangeForm(e) {
    return setForm((form) => {
      return {
        ...form,
        [e.target.name]: e.target.value,
      };
    });
  }

  const totalVariationPrice = cart.reduce((acc, cur) => {
    const variationPriceSum = cur.variation.reduce((variationAcc, variationStr) => {
      if (typeof variationStr === 'string') {
        const selectedVariation = JSON.parse(variationStr);
        return variationAcc + selectedVariation.ProductVariationPrice;
      } else if (Array.isArray(variationStr)) {
        return variationAcc + variationStr.reduce((acc, itm) => {
          const selectedVariation = JSON.parse(itm);
          return acc + selectedVariation.ProductVariationPrice;
        }, 0);
      }
    }, cur.basePrice);

    return acc + variationPriceSum * cur.qty;
  }, 0);

  const totalDiscount = user ? discountArray.reduce((acc, item) => acc + item.discountPrice, 0).toFixed(2) : 0;

  const totalPrice = (totalVariationPrice - totalDiscount).toFixed(2);

  const disabled = form.payment === "";

  const submitOrderHandler = async () => {
    const obj = {
      userMembership: user?.UserMembership || '-',
      tableId: tableId,
      promotionCodeId: 0,
      paymentMethodId: form.payment,
      userPaymentMethodId: form.payment,
      orderTotalAmount: totalPrice,
      orderPaidAmount: 0,
      orderShippingFeeAmount: 0,
      orderTaxAmount: 0,
      transactionUuid: 0,
      pickupInd: 0,
      orderOrigin: 'web',
      orderType: cart.map((item) => 1).join(";"),
      productId: cart.map((item) => item.productId).join(";"),
      productQuantity: cart.map((item) => item.qty).join(";"),
      promotionId: cart.map((item) => 0).join(";"),
      promotionItemId: cart.map((item) => 0).join(";"),
      detailRemark: cart.map((item) => '-').join(";"),
      discountedProductId: user ? discountArray.map((item) => item.productId).join(";") : discountArray.map((item) => 0).join(";"),
      customizationTypeId: (checked.length > 0 ? checked : cart).map((item) =>
        item.variation.length > 0
          ? item.variation.map((itm) =>
            Array.isArray(itm)
              ? !isEmpty(itm) ? itm.map((i) => JSON.parse(i).ProductVariationID)
                : 0
              : JSON.parse(itm).ProductVariationID
          )
          : 0
      ).join(';'),
      customizationDetailsId: (checked.length > 0 ? checked : cart)
        .map((item) =>
          item.variation.length > 0
            ? item.variation.map((itm) =>
              Array.isArray(itm)
                ? !isEmpty(itm) ? itm.map((i) => JSON.parse(i).ProductVariationID)
                  : 0
                : JSON.parse(itm).ProductVariationDetailID
            )
            : 0
        ).join(';'),
    };

    dispatch(submitOrder(obj));
  };

  const payHandler = () => {
    if (disabled) return;
    if (cart.length < 1)
      return toast.error("Add at least 1 product to your cart");

    if (!user) {
      setLoginModal(true);
      return;
    }

    submitOrderHandler();
  };

  const handleRemove = (item) => {
    dispatch(deleteItemFromCart({
      id: item.id,
      user: user || null,
    }));
  }

  const handlePlus = (item) => {
    dispatch(incrementQty({
      id: item.id,
      user: user || null,
    }));
  }

  const handleMinus = (item) => {
    const { qty, id } = item;
    if (qty - 1 < 1) return handleRemove(item);
    dispatch(decrementQty({
      id: id,
      user: user || null,
    }));
  }

  const handleContinue = () => {
    setLoginModal(false);
    submitOrderHandler();
  }

  return (
    <>
      <Header />

      <main>
        <div className="global-px space-y-3 py-3">
          <Box>
            <Button
              variant='text'
              onClick={() => navigate(-1)}
              startIcon={<Iconify icon="carbon:chevron-left" />}
            >
              Back
            </Button>
          </Box>
          <section className="flex flex-col md:flex-row lg:gap-16">
            <aside className="flex-1 flex">
              <section className="flex bg-white rounded-lg lg:p-7 flex-col w-full">
                <div className="w-full my-4 lg:my-6">
                  <p className="text-quantenary font-bold text-xl lg:text-3xl text-center">
                    Order Summary
                  </p>
                </div>
                {cart.length > 0 ? (
                  <>
                    <section className="flex w-full flex-col gap-4 my-4">
                      {cart.map((item, idx) => (
                        <CartItem
                          key={idx}
                          item={item}
                          idx={idx}
                          handlePlus={handlePlus}
                          handleMinus={handleMinus}
                          setRemove={handleRemove}
                        />
                      ))}
                    </section>
                    <hr />
                    <section className="flex flex-col w-full my-4">
                      <div className="flex flex-row uppercase lg:text-lg">
                        <p className="flex-[2_2_0%] font-normal">Subtotal</p>
                        <p className="flex-1 lg:flex-none text-right font-normal">
                          RM {totalVariationPrice.toFixed(2)}
                        </p>
                      </div>
                      <div className="flex flex-row uppercase lg:text-lg">
                        <p className="flex-[2_2_0%] font-normal">Discount</p>
                        <p className="flex-1 lg:flex-none text-right font-normal">- RM {totalDiscount}</p>
                      </div>
                      <div className="flex flex-row uppercase lg:text-xl font-bold my-10 items-center">
                        <p className="flex-[2_2_0%] font-bold">Total</p>
                        <p className="flex-initial lg:flex-none font-bold text-lg">
                          RM {totalPrice}
                        </p>
                      </div>
                    </section>

                    <aside className="flex-1 flex flex-col gap-5">
                      <p className="text-quantenary font-bold text-xl lg:text-3xl text-center">
                        Payment method
                      </p>
                      <section className="bg-white rounded-xl p-5 lg:p-7 space-y-3">
                        {paymentMethods.map((method, idx) => (
                          <>
                            <div key={idx} className="flex gap-2 items-center">
                              <input
                                type="radio"
                                className="accent-primary w-4 h-4"
                                name="payment"
                                value={method.PaymentMethodTypeID}
                                id={`payment${method.PaymentMethodTypeID}`}
                                checked={form.payment === (method.PaymentMethodTypeID).toString()}
                                onChange={onChangeForm}
                              />
                              <label
                                htmlFor={`payment${method.PaymentMethodTypeID}`}
                                className="flex items-center gap-2 font-normal"
                              >
                                <Iconify icon={method.PaymentMethodIcon} width={30} color={method.PaymentMethodColor} />
                                {method.PaymentMethodType}
                              </label>
                            </div>
                            {idx !== paymentMethods.length - 1 && <hr />}
                          </>
                        ))}
                      </section>
                      <button
                        disabled={disabled}
                        onClick={payHandler}
                        className={`${isSubmitting && "loading"
                          } btn btn-block btn-primary py-4 font-bold rounded-lg disabled:bg-opacity-100`}
                      >
                        Confirm and Pay
                      </button>
                    </aside>
                  </>
                ) : (
                  <div className="flex flex-col py-5" style={{ alignItems: 'center' }}>
                    <img src={illustrationsPromo} width={200} />
                    <p className="text-primary font-semibold text-xl">Empty cart</p>
                    <p className="text-black font-medium text-sm mt-1">
                      Your cart is empty, let&apos;s add some items
                    </p>
                  </div>
                )}
              </section>
            </aside>
          </section>
        </div>
      </main>

      <Modal
        isOpen={loginModal}
        onClose={() => setLoginModal(false)}
        className="flex flex-col gap-y-5 text-center"
      >
        Do you want to login to continue transaction?
        <div className="mx-auto space-x-3">
          <button
            onClick={() => {
              navigate("/auth/login", { state: { from: "/cart" } });
            }}
            className="btn btn-primary "
          >
            Yes
          </button>
          <div onClick={() => handleContinue()} className="btn bg-white">
            No
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Cart;
